import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { Subject } from 'rxjs';
import {takeUntil } from 'rxjs/operators';
import { AuthMultinationalService } from '../authentication/auth-multinational.service';
import { MultinationalService } from '../services/multinational.service';
import { BlockUIModule } from 'primeng/blockui';
import { CommonModule } from '@angular/common';
@Component({
    selector: 'app-countries',
    templateUrl: './countries.component.html',
    styleUrls: ['./countries.component.scss'],
    imports: [
      CommonModule,
      BlockUIModule
    ]
})
export class CountriesComponent implements OnInit {

  blocked: boolean = false;
  destroy$: Subject<boolean> = new Subject<boolean>();
  loading: boolean = false;
  countries: any = [];

  constructor(
    private router: Router,
    private messageService: MessageService,
    private multinationalService: MultinationalService,
    private authMultinationalService: AuthMultinationalService
  ) { }

  ngOnInit(): void {
    this.getCountriesList();
  }

  getCountriesList(){
    this.loading = true;
    this.multinationalService.getCountriesList().pipe(takeUntil(this.destroy$)).subscribe(
      (res: any) => {
        //console.log(res.replace(/\s+/g, ''))
        /* res.map((element: any) =>{
          if(element.country_name == "Senegal") {
            element.country_code = "SN"
          }
          return element;
        }) */
        this.countries = [... res].sort((a: any, b: any) => a?.country_name.localeCompare(b?.country_name));
        this.loading = false;
      },
      (err: any) => {
        this.messageService.clear();
        this.messageService.add({key: 'KeyHub', severity:'error', summary:'Erreur', detail: JSON.stringify(err)});
      }
    );
  }

  async changeCountry(code: string){
    this.blocked = true;
    localStorage.clear()
    localStorage.setItem("country_foodbeeper_hub", code);

    await this.authMultinationalService.getCurrentCountry()
    //location.reload()
    this.router.navigate(['login']).then(
      () => {
        this.blocked = false;
        location.reload()
      }
    )
  }
}
