import { Action, createReducer, on } from '@ngrx/store';
import * as UserLocationActions from './user-location.actions'


export const userLocationFeatureKey = 'userLocation';

export interface State {
  
}

export const initialState: State = {
  userLocation: <any>{},
  error: ''
};

export const reducer = createReducer(
  initialState,
  
  on(UserLocationActions.loadUserLocations, (state) => ({
    ...state
  })),

  on(UserLocationActions.loadUserLocationsSuccess, (state, action) => ({
    ...state,
    userLocation: action.data
  })),

  on(UserLocationActions.loadUserLocationsFailure, (state, action) => ({
    ...state,
    error: action.error
  })),
);
