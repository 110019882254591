import { Action, createReducer, on } from '@ngrx/store';
import * as StaffActions from './staff.actions'


export const staffFeatureKey = 'staff';

export interface State {

}

export const initialState: State = {
  staff: <any>[],
  error: <any>''
};

export const reducer = createReducer(
  initialState,
  on(StaffActions.loadStaffs, (state) => ({
    ...state
  })),

  on(StaffActions.loadStaffsSuccess, (state, action) => ({
    ...state,
    staff: action.data
  })),

  on(StaffActions.loadStaffsFailure, (state, action) => ({
    ...state,
    error: action.error
  })),
);
