export const environment = {
  env: 'staging',
  baseApiUrl: "https://api.foodbeeper.com/",
  dynamicApiUrl: "https://api.foodbeeper.com/i?e=staging",
  apiUrl: "https://staging.foodbeeper.com/api/",
  appVersion: 'stag - v' + require('../../package.json').version,
  production: false,

  firebaseConfig: {
    apiKey: "AIzaSyCB_WCuEc8xgBWbd-8XDdr2VmJ9eQ11wmA",
    authDomain: "foodbeeper-app-staging.firebaseapp.com",
    databaseURL: localStorage.getItem("country_db_url_foodbeeper_hub") || "https://foodbeeper-app-staging.firebaseio.com",
    projectId: "foodbeeper-app-staging",
    storageBucket: "foodbeeper-app-staging.appspot.com",
    messagingSenderId: "377821911866",
    appId: "1:377821911866:web:e3b11ac7aab68685654e03",
    measurementId: "G-19TGS0PC9L"
  },
};