import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { AuthService } from '../authentication/auth.service';
import { getCurrentUserLocation } from 'src/app/ngrx/user-location/user-location.selectors'
import { select, Store } from '@ngrx/store';
import { Subject, takeUntil} from 'rxjs';
import { ToastModule } from 'primeng/toast';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    imports: [
      ToastModule,
      CommonModule,
      ReactiveFormsModule
    ]
})
export class LoginComponent implements OnInit {

  complete: boolean = false;

  currentUserLocation: any = <any>{ }

  destroy$: Subject<boolean> = new Subject<boolean>()

  loginForm: any;
  initLoginForm(){
    this.loginForm = new FormGroup({
      username: new FormControl('', [Validators.required, Validators.minLength(1)]),
      password: new FormControl('', [Validators.required, Validators.minLength(1)]),
    })
  }

  constructor(
    private router : Router,
    private authService : AuthService,
    private store: Store,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
    this.initLoginForm();

    this.store.pipe(select(getCurrentUserLocation), takeUntil(this.destroy$)).subscribe(
      (data: any) => {
        this.currentUserLocation = data;
      }
    )
  }

  login(btn: any){
    btn.disabled = true;
    let isAgentLogin: any; let isLogin: any;

    if (this.loginForm.valid) {
      isAgentLogin = new Promise((resolve) => {
        this.authService.agentLogin(this.loginForm.value).subscribe(
          (res: any)=>{
            resolve(true);
          },
          (err: any)=>{
            this.messageService.clear();
            this.messageService.add({key: 'KeyLogin', severity:'error', summary:'Erreur', detail: JSON.stringify(err)});
            this.complete = false; btn.disabled = false;
            resolve(false);
          }
        );
      })

      isLogin = new Promise((resolve) => {
        this.authService.login(this.loginForm.value, "token_auth_agent").subscribe(
          (res: any)=>{
            resolve(true);
          },
          (err: any)=>{
            this.messageService.clear();
            this.messageService.add({key: 'KeyLogin', severity:'error', summary:'Erreur', detail: JSON.stringify(err)});
            this.complete = false; btn.disabled = false;
            resolve(false);
          }
        );
      })

      Promise.all([isAgentLogin, isLogin]).then((values) => {
        if(values[0] && values[1]){
          this.complete = true;
          //setTimeout(() => {
            if(location.hash == "#/login"){
              //console.log(this.router)
              this.router.navigateByUrl('/hubs-monitor').then(() => {
                btn.disabled = false;
              }); 
            }else{
              location.reload()
            }
          //}, 1000);
        }
      });

    }else {
      this.loginForm.markAllAsTouched();
      this.complete = false;
      btn.disabled = false;
    }
  }

}
