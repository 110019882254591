import { Action } from '@ngrx/store';
import { DeliveryActions, DeliveryActionTypes } from './delivery.actions';


export const deliveryFeatureKey = 'delivery';

export interface State {
  delivery: any; 
  error: any;
}

export const initialState: State = {
  delivery: <any>{},
  error: <any>''
};

export function reducer(state = initialState, action: DeliveryActions): State {
  switch (action.type) {

    case DeliveryActionTypes.LoadDeliverys:
      return {
        ...state
      }

    case DeliveryActionTypes.LoadDeliverysSuccess:
      return {
        ...state,
        delivery: action.payload.data,
        error: '',
      }
      
      case DeliveryActionTypes.LoadAddedDeliverysSuccess:
        return {
          ...state,
          delivery: {
            ...state.delivery,
            [action.payload.key]: action.payload.data
          },
          error: '',
        }

      case DeliveryActionTypes.LoadChangedDeliverysSuccess:
        return {
          ...state,
          delivery: {
            ...state.delivery,
            [action.payload.key]: action.payload.data
          },
          error: '',
        }

      case DeliveryActionTypes.LoadRemovedDeliverysSuccess:
        let delivery = {...state.delivery};
        delete delivery[action.payload.key];
        return {
          ...state,
          delivery: delivery,
          error: '',
        }

    case DeliveryActionTypes.LoadDeliverysFailure:
      return {
        ...state,
        delivery: <any>{},
        error: action.payload.error,
      }

    default:
      return state;
  }
}
