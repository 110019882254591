import { Action } from '@ngrx/store';
import { OnlineVisitorsActions, OnlineVisitorsActionTypes } from './online-visitors.actions';


export const onlineVisitorsFeatureKey = 'onlineVisitors';

export interface State {

}

export const initialState: State = {
  online_visitors: <any>[],
  current_hub_online_visitors: <any>{},
  error: ''
};

export function reducer(state = initialState, action: OnlineVisitorsActions): State {
  switch (action.type) {

    case OnlineVisitorsActionTypes.LoadCurrentHubOnlineVisitors:
      return {
        ...state
      }

    case OnlineVisitorsActionTypes.LoadCurrentHubOnlineVisitorsSuccess:
      return {
        ...state,
        current_hub_online_visitors: action.payload.data,
        error: '',
      }

    case OnlineVisitorsActionTypes.LoadCurrentHubOnlineVisitorsFailure:
      return {
        ...state,
        online_visitors: <any>[],
        current_hub_online_visitors: <any>{},
        error: action.payload.error,
      }

    default:
      return state;
  }
}
