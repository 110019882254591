import { createFeatureSelector, createSelector } from '@ngrx/store';

import { State } from './hub-manager.reducer'

const getHubManagerFeatureState = createFeatureSelector<State>('hubManager')

export const getCurrentHubManager = createSelector(
    getHubManagerFeatureState,
    (state: any) => state.hubManager
)

export const getCurrentError = createSelector(
    getHubManagerFeatureState,
    (state: any) => state.error
)