import { Injectable } from '@angular/core';
import { HttpserviceService } from './httpservice.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MultinationalService {

  env = "staging"

  constructor(
    private httpserviceService: HttpserviceService
  ) {
    (environment.env == "production") ? this.env = "production" : this.env = "staging";
  }

  getCountriesList() {
    return this.httpserviceService.get("i?e=" + this.env + "&l=1", undefined, environment.baseApiUrl);
  }

  getCountry() {
    return this.httpserviceService.get("i?e=" + this.env, undefined, environment.baseApiUrl);
  }

  getCountryInfo(code: string){
    return this.httpserviceService.get("i?e=" + this.env + "&c=" + code, undefined, environment.baseApiUrl);
  }
}
