import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { MessageService } from 'primeng/api';
import { Observable, Subject, take, takeUntil } from 'rxjs';
import * as fromUser from 'src/app/ngrx/user/user.selectors'

@Injectable({
  providedIn: 'root'
})
export class PermissionsGuard implements CanActivate {

  destroy$: Subject<boolean> = new Subject<boolean>()
  currentAgent: any;

  constructor(
    private store: Store,
    public router: Router,
    private messageService: MessageService
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      let roles = route.data.expectedPermission as Array<string>;
      try {
        console.log("1")
        return new Promise(async (resolve) => {
          await this.store.pipe(select(fromUser.getCurrentUser), take(1)).subscribe(
            (user: any) =>{
              this.currentAgent = user;
              let permissions = this.currentAgent?.profile?.permissions
              if(permissions?.fleet_manager == 0) permissions.customer_service = 1;
              permissions = Object.entries(permissions || {}).filter(([key, value]) => value == 1).map(([key, value]) => key);

              if(roles == null){
                this.messageService.clear();
                this.messageService.add({key: 'KeyHome', severity:'error', summary: 'Erreur', detail: "Vous n'avez pas la permission"});
                this.router.navigateByUrl('/')
                resolve (false);
              }

              let role = null;
              for (const item of permissions) {
                role = roles.find(( element ) => element == item);
                if(role){
                  break;
                }
              }

              if (role) {
                resolve (true);
              }else{
                this.messageService.clear();
                this.messageService.add({key: 'KeyHome', severity:'error', summary: 'Erreur', detail: "Vous n'avez pas la permission"});
                this.router.navigateByUrl('/');
                resolve (false);
              }
            },
            (error: any) => {
              console.log(error);
              this.messageService.clear();
              this.messageService.add({key: 'KeyHome', severity:'error', summary: 'Erreur', detail: "Vous n'avez pas la permission"});
              this.router.navigateByUrl('/')
              resolve (false);
            }
          )
        })
      }catch(e){
        console.log(e);
        this.messageService.clear();
        this.messageService.add({key: 'KeyHome', severity:'error', summary: 'Erreur', detail: "Vous n'avez pas la permission"});
        this.router.navigateByUrl('/')
        return false;
      }
  }
  
}
