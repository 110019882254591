import { Action } from '@ngrx/store';

export enum OnlineActionTypes {
  LoadOnlines = '[Online] Load Onlines',
  LoadOnlinesSuccess = '[Online] Load Onlines Success',
  LoadOnlinesFailure = '[Online] Load Onlines Failure',
}

export class LoadOnlines implements Action {
  readonly type = OnlineActionTypes.LoadOnlines;
}

export class LoadOnlinesSuccess implements Action {
  readonly type = OnlineActionTypes.LoadOnlinesSuccess;
  constructor(public payload: { data: any }) { }
}

export class LoadOnlinesFailure implements Action {
  readonly type = OnlineActionTypes.LoadOnlinesFailure;
  constructor(public payload: { error: any }) { }
}

export type OnlineActions = LoadOnlines | LoadOnlinesSuccess | LoadOnlinesFailure;

