import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Partitions } from '../interfaces/partitions';

@Injectable({
  providedIn: 'root'
})
export class GlobalInjectableService {

  /** ----------------------------------------------------------------------------- **/
  isSplashScreen: boolean = false;

  private isSplashScreenGlobal = new  BehaviorSubject(this.isSplashScreen);
  currentIsSplashScreen = this.isSplashScreenGlobal.asObservable();

  changeIsSplashScreen(isSplashScreen: any) {
    this.isSplashScreenGlobal.next(isSplashScreen)
  }

  /** ----------------------------------------------------------------------------- **/
  isFirebaseConnected: boolean = false;

  private isFirebaseConnectedGlobal = new  BehaviorSubject(this.isFirebaseConnected);
  currentIsFirebaseConnected = this.isFirebaseConnectedGlobal.asObservable();

  changeIsFirebaseConnected(isFirebaseConnected: any) {
    this.isFirebaseConnectedGlobal.next(isFirebaseConnected)
  }

  /** ----------------------------------------------------------------------------- **/
  isAuthenticated: any = null;

  private isAuthenticatedGlobal = new  BehaviorSubject(this.isAuthenticated);
  currentIsAuthenticated = this.isAuthenticatedGlobal.asObservable();

  changeIsAuthenticated(isAuthenticated: any) {
    this.isAuthenticatedGlobal.next(isAuthenticated)
  }

  /** ----------------------------------------------------------------------------- **/
  isAuthenticatedHub: any = null;

  private isAuthenticatedHubGlobal = new  BehaviorSubject(this.isAuthenticatedHub);
  currentIsAuthenticatedHub = this.isAuthenticatedHubGlobal.asObservable();

  changeIsAuthenticatedHub(isAuthenticatedHub: any) {
    this.isAuthenticatedHubGlobal.next(isAuthenticatedHub)
  }

  /** ----------------------------------------------------------------------------- **/
  currentPartition: Partitions = Partitions.all;

  private currentPartitionGlobal = new  BehaviorSubject(this.currentPartition);
  currentCurrentPartition = this.currentPartitionGlobal.asObservable();

  changeCurrentPartition(currentPartition: any) {
    this.currentPartitionGlobal.next(currentPartition)
  }

  constructor() { }
}
