import { createFeatureSelector, createSelector } from '@ngrx/store';


import { State } from './hub.reducer'

const getHubFeatureState = createFeatureSelector<State>('hub')

export const getCurrentHub = createSelector(
    getHubFeatureState,
    (state: any) => state.hub
)

export const getCurrentError = createSelector(
    getHubFeatureState,
    (state: any) => state.error
)