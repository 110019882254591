<div class="container d-flex flex-column align-items-center justify-content-center" style="min-height: 100vh;">
    <div class="row">
        <div class="col-sm-12 col-md-12 col-lg-12 d-flex align-items-center">
            <img src="/assets/images/500 Internal Server Error-bro.svg" width="450px" height="400px" alt="500">
        </div>
    </div>
    <div class="row">
        <div class="col-sm-12 col-md-12 col-lg-12">
            <div class="text-center w-100">
                <button class="btn btn-success-fbeeper" style="background-color: var(--green-hub-fbeeper);" routerLink="/">Retour</button>
            </div>
        </div>
    </div>
</div>