import { Action } from '@ngrx/store';

export enum StatusActionTypes {
  LoadStatuss = '[Status] Load Statuss',
  LoadStatussSuccess = '[Status] Load Statuss Success',
  LoadStatussFailure = '[Status] Load Statuss Failure',
}

export class LoadStatuss implements Action {
  readonly type = StatusActionTypes.LoadStatuss;
}

export class LoadStatussSuccess implements Action {
  readonly type = StatusActionTypes.LoadStatussSuccess;
  constructor(public payload: { data: any }) { }
}

export class LoadStatussFailure implements Action {
  readonly type = StatusActionTypes.LoadStatussFailure;
  constructor(public payload: { error: any }) { }
}

export type StatusActions = LoadStatuss | LoadStatussSuccess | LoadStatussFailure;

