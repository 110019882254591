import { Injectable } from '@angular/core';
import { Observable, Subject, throwError } from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { catchError, retry, takeUntil } from 'rxjs/operators';
//import { GlobalMessageService } from './global-message.service';
import { Router } from '@angular/router';
import { GlobalService } from './global.service';
import { select, Store } from '@ngrx/store';
import * as fromHub from 'src/app/ngrx/hub/hub.selectors'
import { MessageService } from 'primeng/api';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HttpserviceService {

  baseUrl;
  headers: any;
  destroy$: Subject<boolean> = new Subject<boolean>()
  currentHub: any = {};

  constructor(
    private globalService: GlobalService,
    private router: Router,
    public http: HttpClient,
    private store: Store,
    private messageService: MessageService
    //private globalMessageService: GlobalMessageService,
  ) { 
    this.baseUrl = globalService.getUrl();
    //console.log(environment)

    this.store.pipe(select(fromHub.getCurrentHub), takeUntil(this.destroy$)).subscribe(
      (hub: any) =>{
        this.currentHub = hub;
      }
    )
  }

  initHeaders(token_name: string) {
    this.headers = new  HttpHeaders().set("Authorization", "Bearer " + localStorage.getItem(token_name))
  }

  get(endpoint: any, token_name = ("token_hub_"+this.currentHub?.profile?.hub_id), baseUrl = environment.apiUrl){
    //console.log(environment.apiUrl)
    this.initHeaders(token_name);
    return this.http.get(baseUrl + endpoint, {headers: this.headers}).pipe(catchError(this.handleErrorFunction));
  }

  post(data: any, endpoint: any, token_name = ("token_hub_"+this.currentHub?.profile?.hub_id), baseUrl = environment.apiUrl) {
    const agentToken = localStorage.getItem('sub_token_hub_agent')
    if(agentToken && agentToken != 'undefined'){
      data.hub_agent_token = agentToken
    }else{
      localStorage.removeItem("token_auth_agent");
      this.router.navigate(['login']);
    }

    this.initHeaders(token_name);
    return this.http.post(baseUrl + endpoint, data, {headers: this.headers}).pipe(catchError(this.handleErrorFunction));
  }

  patch(data: any, endpoint: any, token_name = ("token_hub_"+this.currentHub?.profile?.hub_id), baseUrl = environment.apiUrl) {
    const agentToken = localStorage.getItem('sub_token_hub_agent')
    if(agentToken && agentToken != 'undefined'){
      data.hub_agent_token = agentToken
    }else{
      localStorage.removeItem("token_auth_agent");
      this.router.navigate(['login']);
    }

    this.initHeaders(token_name);
    return this.http.patch(baseUrl + endpoint, data, {headers: this.headers}).pipe(catchError(this.handleErrorFunction));
  }

  delete(endpoint: any, token_name = ("token_hub_"+this.currentHub?.profile?.hub_id), baseUrl = environment.apiUrl){
    this.initHeaders(token_name);
    return this.http.delete(baseUrl + endpoint, {headers: this.headers}).pipe(catchError(this.handleErrorFunction));
  }

  public handleErrorFunction = (error: any) => {
    //console.log(" handleErrorFunction ", error?.status, error);
    //this.globalMessageService.clear();
    let errorList = [];
    switch (error.status) {
      case 0: {
        return throwError("Can not reach the server!");
      }
      case 400: {
        /* for (let [key, value] of Object.entries(error.error)) {
          errorList.push(key + ": " +value + " \n")
        } */
        return throwError(error?.error?.detail || error?.detail || error?.error);
      }
      case 401: {
        //this.router.navigate(['login'])
        return throwError(error?.error?.message || error?.message);
      }
      case 403: {
        return throwError(error.error.detail || "Non autorisé");
      }
      case 404: {
        return throwError(error?.error?.message || error?.statusText || "Introuvable");
      }
      case 405: {
        return throwError(error?.error?.message || error?.statusText || "Méthode Non Autorisée");
      }
      case 422: {
        return throwError(Object.values(error?.error?.errors || {}) || error?.error?.message || error?.statusText || "Invalide");
      }
      case 500: {
        this.router.navigateByUrl("/fatal", { skipLocationChange: true });
        return throwError("fatal");
      }
      case 502: {
        this.router.navigateByUrl("/fatal", { skipLocationChange: true });
        return throwError("fatal");
      }
      case 504: {
        this.router.navigateByUrl("/fatal", { skipLocationChange: true });
        return throwError("fatal");
      }
      default:
        //console.log("301")
        this.router.navigateByUrl('oops', { skipLocationChange: true }).then(
          () => {
            return throwError(error?.error?.detail || error?.detail);
          })
    }
    return throwError(error?.error?.detail || error?.detail || error?.error || "Erreur");
  };
}
