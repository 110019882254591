import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import * as hubManagerActions from './hub-manager.actions';
import { FirebaseService } from 'src/app/services/firebase.service';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { Observable, of } from 'rxjs';



@Injectable()
export class HubManagerEffects {

  constructor(
    private actions$: Actions,
    private firebaseService: FirebaseService,
  ) { }

  loadHubManager$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(hubManagerActions.HubManagerActionTypes.LoadHubManagers),
      mergeMap(
        action => this.firebaseService.getStatusHubManager().valueChanges().pipe(
          map(hubManager => {
            let setting: any = {... hubManager}
            setting.delivery_zones_active = Object.entries(setting?.delivery_zones || []).filter(([key, value]: any) => (value?.can_deliver == 1 && value?.available == 1));
            return (new hubManagerActions.LoadHubManagersSuccess({data: setting}))
          }),
          catchError(err => of(new hubManagerActions.LoadHubManagersFailure({error: err})))
        )
      )
    )
  )
}
