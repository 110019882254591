import { createFeatureSelector, createSelector } from '@ngrx/store';

import { State } from './user.reducer'

const getUserFeatureState = createFeatureSelector<State>('user')

export const getCurrentUser = createSelector(
    getUserFeatureState,
    (state: any) => state.user
)

export const getCurrentError = createSelector(
    getUserFeatureState,
    (state: any) => state.error
)