import { Action } from '@ngrx/store';

export enum DeliveryActionTypes {
  LoadDeliverys = '[Delivery] Load Deliverys',
  LoadDeliverysSuccess = '[Delivery] Load Deliverys Success',
  LoadAddedDeliverysSuccess = '[Delivery] Load Added Deliverys Success',
  LoadChangedDeliverysSuccess = '[Delivery] Load Changed Deliverys Success',
  LoadRemovedDeliverysSuccess = '[Delivery] Load Removed Deliverys Success',
  LoadDeliverysFailure = '[Delivery] Load Deliverys Failure',
}

export class LoadDeliverys implements Action {
  readonly type = DeliveryActionTypes.LoadDeliverys;
}

export class LoadDeliverysSuccess implements Action {
  readonly type = DeliveryActionTypes.LoadDeliverysSuccess;
  constructor(public payload: { key:any, data: any }) { }
}

export class LoadAddedDeliverysSuccess implements Action {
  readonly type = DeliveryActionTypes.LoadAddedDeliverysSuccess;
  constructor(public payload: { key:any, data: any }) { }
}

export class LoadChangedDeliverysSuccess implements Action {
  readonly type = DeliveryActionTypes.LoadChangedDeliverysSuccess;
  constructor(public payload: { key:any, data: any }) { }
}

export class LoadRemovedDeliverysSuccess implements Action {
  readonly type = DeliveryActionTypes.LoadRemovedDeliverysSuccess;
  constructor(public payload: { key:any, data: any }) { }
}

export class LoadDeliverysFailure implements Action {
  readonly type = DeliveryActionTypes.LoadDeliverysFailure;
  constructor(public payload: { error: any }) { }
}

export type DeliveryActions = LoadDeliverys | LoadDeliverysSuccess | LoadAddedDeliverysSuccess | LoadChangedDeliverysSuccess | LoadRemovedDeliverysSuccess | LoadDeliverysFailure;

