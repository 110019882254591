import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CountdownPipe } from './countdown.pipe';
import { OrderStatusPipe } from './order-status.pipe';
import { DeliveryStatusPipe } from './delivery-status.pipe';
import { OrderStatusDetailPipe } from './order-status-detail.pipe';
import { WeekdaysPipe } from './weekdays.pipe';
import { DateAgoPipe } from './date-ago.pipe';
import { ErrandStatusPipe } from './errand-status.pipe';
import { ImagesPipe } from './images.pipe';



@NgModule({
  declarations: [
    CountdownPipe,
    OrderStatusPipe,
    DeliveryStatusPipe,
    OrderStatusDetailPipe,
    WeekdaysPipe,
    DateAgoPipe,
    ErrandStatusPipe,
    ImagesPipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    CountdownPipe,
    OrderStatusPipe,
    DeliveryStatusPipe,
    OrderStatusDetailPipe,
    WeekdaysPipe,
    ErrandStatusPipe,
    ImagesPipe
  ]
})
export class PipeTransformerModule { 

  static forRoot() {
    return {
        ngModule: PipeTransformerModule,
        providers: [],
    };
  }

}
