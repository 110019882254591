import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { HttpserviceService } from '../services/httpservice.service';
import { Observable, throwError } from 'rxjs';
import { catchError, delay, map } from 'rxjs/operators';
import * as UserActions from 'src/app/ngrx/user/user.actions'
import * as HubActions from 'src/app/ngrx/hub/hub.actions'
import { Store } from '@ngrx/store';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GlobalInjectableService } from '../services/global-injectable.service';
import { GlobalService } from '../services/global.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private jwtHelper = new JwtHelperService();

  constructor(
    public httpserviceService: HttpserviceService, 
    public router: Router,
    private store: Store,
    private http: HttpClient,
    private globalInjectableService: GlobalInjectableService,
    private globalService: GlobalService
  ) { }

  public async isAuthenticated(): Promise<boolean | UrlTree | Observable<boolean | UrlTree>> {
    var token_auth_agent = localStorage.getItem('token_auth_agent')
    var sub_token_hub_agent = localStorage.getItem('sub_token_hub_agent')
    
    try{
      if (!sub_token_hub_agent || !token_auth_agent || this.jwtHelper.isTokenExpired(token_auth_agent)){
        //console.log('isAuthenticated if')
        this.globalInjectableService.changeIsAuthenticated(false);
        return false;
      }else{
        try{
          //console.log('isAuthenticated else')
          //this.globalInjectableService.changeIsAuthenticated(false);
          return new Promise(async (resolve) => {
            await this.httpserviceService.get("authenticated_user", "token_auth_agent").subscribe(
              (user: any) => {
                //console.log('isAuthenticated res')
                this.globalInjectableService.changeIsAuthenticated(true);
                this.store.dispatch(new UserActions.LoadUsersSuccess(user));
                resolve (true);
              },
              (err: any) => {
                //console.log('isAuthenticated err')
                this.globalInjectableService.changeIsAuthenticated(false);
                resolve (false);
              }
            )
          })
        }catch(Error){
          //console.log('isAuthenticated catch', Error)
          this.globalInjectableService.changeIsAuthenticated(false);
          return false;
        }
      }
    }catch(Error){
      //console.log('isAuthenticated catch', Error)
      this.globalInjectableService.changeIsAuthenticated(false);
      return false;
    }
  }

  public async isAuthenticatedHub(hub_id: number): Promise<boolean | UrlTree | Observable<boolean | UrlTree>> {
    var token_hub = localStorage.getItem('token_hub_' + hub_id)
    //console.log(hub_id)
    try{
      if (!token_hub || this.jwtHelper.isTokenExpired(token_hub)){
        //console.log('isAuthHub if')
        this.globalInjectableService.changeIsAuthenticatedHub(false);
        return false;
      }else{
        try{
          //console.log('isAuthHub else')
          //this.globalInjectableService.changeIsAuthenticatedHub(false);
          return new Promise(async (resolve) => {
            await this.httpserviceService.get("authenticated_user", "token_hub_" + hub_id).subscribe(
              (hub: any) => {
                //console.log('isAuthHub res')
                this.globalInjectableService.changeIsAuthenticatedHub(true);
                this.store.dispatch(new HubActions.LoadHubsSuccess(hub));
                resolve (true);
              },
              (err: any) => {
                //console.log('isAuthHub err')
                this.globalInjectableService.changeIsAuthenticatedHub(false);
                resolve (false);
              }
            )
          })
        }catch(Error){
          //console.log('isAuthHub catch', Error)
          this.globalInjectableService.changeIsAuthenticatedHub(false);
          return false;
        }
      }
    }catch(Error){
      //console.log('isAuthHub catch', Error)
      this.globalInjectableService.changeIsAuthenticatedHub(false);
      return false;
    }
  }

  getAuthenticatedUser(){
    //console.log("getAuthenticatedUser")
    return this.httpserviceService.get("authenticated_user")
  }

  getUserByToken(token: string){
    return this.httpserviceService.http.get(environment.apiUrl + "authenticated_user", {headers: new  HttpHeaders().set("Authorization", "Bearer " + token)}).pipe(catchError(this.httpserviceService.handleErrorFunction));
  }

  login(data: any, token_name = "token") {
    return this.httpserviceService.post(data, "authenticate").pipe(
      map((result: any) => {
        localStorage.setItem(token_name, result['token']);
        return result;
      }),
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  agentLogin(data: any) {
    return this.httpserviceService.post(data, "hub_agent/authenticate").pipe(
      map((result: any) => {
        localStorage.setItem('sub_token_hub_agent', result.meta['token']);
        return true;
      }),
      catchError((err) => {
        return throwError(err);
      })
    );
  }

}
