<p-blockUI [blocked]="blocked"></p-blockUI>
<section class="countries-page">
    <div class="container-fluid d-flex justify-content-center align-items-center" style="min-height: 100vh;" >
        <div class="text-center">
            <!-- <embed type="application/json" src="https://lottie.host/?file=ca6309e2-ea75-4ce2-89a9-695cd6413ae2/3u9l5ydWFa.json" width="50" height="50"> -->
            <img class="mb-4" src="../../assets/images/located-country.gif" width="150" height="150">
            <ul style="list-style: none;" class="p-0">
                <li class="py-2" *ngFor="let country of countries">
                    <h1 style="color: var(--success-hub-fbeeper); cursor: pointer;"
                        (click)="changeCountry(country?.country_code)">
                        {{country?.country_name}}
                    </h1>
                </li>
            </ul>
        </div>
    </div>
</section>