import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { GlobalInjectableService } from '../services/global-injectable.service';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthHubGuard  {
  
  constructor(
    public auth: AuthService, 
    public router: Router,
    private globalInjectableService: GlobalInjectableService
  ) { }
  
  canActivate(route: ActivatedRouteSnapshot,state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    this.globalInjectableService.changeIsSplashScreen(true);
    return new Promise((resolve) => {
      this.auth.isAuthenticatedHub(route.params.hub_id).then(
        (verify) => {
          if(verify){
            resolve(true);
          }else{
            this.router.navigateByUrl('/hubs-monitor');
            resolve(false);
          }
        })
        .catch(err => {
          resolve(false);
        });
    })
  }

}
