import { Action, createReducer, on } from '@ngrx/store';
import * as ActiveErrandsActions from './active-errands.actions'

export const activeErrandsFeatureKey = 'activeErrands';

export interface State {
  
}

export const initialState: State = {
  activeErrands: <any>{},
  error: ''
};

export const reducer = createReducer(
  initialState,

  on(ActiveErrandsActions.loadActiveErrandss, (state) => ({
    ...state
  })),

  on(ActiveErrandsActions.loadActiveErrandssSuccess, (state, action) => ({
    ...state,
    activeErrands: action.data
  })),

  on(ActiveErrandsActions.loadActiveErrandssFailure, (state, action) => ({
    ...state,
    error: action.error
  })),
);
