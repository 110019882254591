import { Action } from '@ngrx/store';
import { HubManagerActions, HubManagerActionTypes } from './hub-manager.actions';


export const hubManagerFeatureKey = 'hubManager';

export interface State {

}

export const initialState: State = {
  hubManager: <any>{},
  error: <any>''
};

export function reducer(state = initialState, action: HubManagerActions): State {
  switch (action.type) {

    case HubManagerActionTypes.LoadHubManagers:
      return {
        ...state
      }

    case HubManagerActionTypes.LoadHubManagersSuccess:
      return {
        ...state,
        hubManager: action.payload.data,
        error: '',
      }

    case HubManagerActionTypes.LoadHubManagersFailure:
      return {
        ...state,
        hubManager: <any>{},
        error: action.payload.error,
      }

    default:
      return state;
  }
}
