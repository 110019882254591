import { ApplicationConfig, importProvidersFrom, inject, provideAppInitializer } from '@angular/core';
import { provideRouter, withHashLocation, withRouterConfig} from '@angular/router';


import { BrowserModule, provideClientHydration } from '@angular/platform-browser';
import { ConfirmationService, MessageService, SharedModule } from 'primeng/api';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideStore, StoreModule } from '@ngrx/store';
import { reducers, metaReducers } from './reducers';
import { EffectsModule, provideEffects } from '@ngrx/effects';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { provideStoreDevtools, StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { providePrimeNG } from 'primeng/config';
import { MyPreset } from './mypreset'; 
import { AuthMultinationalService } from './authentication/auth-multinational.service';
import { AngularFirePerformanceModule, PerformanceMonitoringService } from '@angular/fire/compat/performance';
import { JwtModule } from '@auth0/angular-jwt';
import { ReactiveFormsModule } from '@angular/forms';
import { AngularFireModule } from '@angular/fire/compat';
import { AppRoutingModule } from './app-routing.module';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { ToastModule } from 'primeng/toast';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { NgrxModule } from './ngrx/ngrx.module';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { TableModule } from 'primeng/table';
import { PaginatorModule } from 'primeng/paginator';
import { ProgressBarModule } from 'primeng/progressbar';
import { BlockUIModule } from 'primeng/blockui';
import { MultiSelectModule } from 'primeng/multiselect';
import { routes } from './app.routes';
import { provideFirebaseApp, initializeApp } from '@angular/fire/app';
import { provideDatabase, getDatabase } from '@angular/fire/database';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes, withHashLocation(), withRouterConfig({onSameUrlNavigation: 'reload'})),
    provideClientHydration(),
    provideAnimations(),
    provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
    provideDatabase(() => getDatabase()),
    MessageService,
    //provideStore(reducers, {metaReducers}),
    //provideStore({'user': UserReducer, 'hubs': HubsReducer, 'currentHub': CurrentHubReducer, 'staff': StaffReducer, 'orders': OrdersReducer}),
    //provideEffects([HubsEffects, CurrentHubEffects]),
    //provideHttpClient(),
    !environment.production ? provideStoreDevtools() : [],
    ConfirmationService,
    //provideStore({'user': UserReducer, 'hubs': HubsReducer, 'currentHub': CurrentHubReducer, 'staff': StaffReducer, 'orders': OrdersReducer}),
    //provideEffects([AtiveErrandsEffects, DeliveryEffects, HubManagerEffects, StoresEffects, AtiveErrandsEffects, UserLocationEffects, DeliveryPositionsEffects]),

    importProvidersFrom(BrowserModule, JwtModule, ReactiveFormsModule, SharedModule, MessagesModule, MessageModule, ToastModule, DialogModule, ButtonModule, TableModule, PaginatorModule, ProgressBarModule, MultiSelectModule, 
      StoreModule.forRoot(reducers, { metaReducers }), 
      NgrxModule,
      EffectsModule.forRoot([]),
      AngularFireModule, AngularFireAuthModule, AngularFireDatabaseModule, AngularFireMessagingModule, 
      AngularFireModule.initializeApp(environment.firebaseConfig),
      !environment.production ? AngularFirePerformanceModule : [], 
      AppRoutingModule
    ),
    MessageService,
    AuthMultinationalService,
    provideAppInitializer(() => {
        const initializerFn = ((authMultinationalService: AuthMultinationalService) => () => authMultinationalService.getCurrentCountry())(inject(AuthMultinationalService));
        return initializerFn();
    }),
    !environment.production ? PerformanceMonitoringService : [],
    provideHttpClient(withInterceptorsFromDi()),
    provideAnimations(),
    provideAnimationsAsync(),

    providePrimeNG({ 
        theme: {
            preset: MyPreset,
            options: {
              cssLayer: {
                  name: 'primeng',
                  order: 'primeng, styles'
              },
              darkModeSelector: false || 'none'
          }
        },
    })
]
};
