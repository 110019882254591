import { Action } from '@ngrx/store';

export enum StoresActionTypes {
  LoadStoress = '[Stores] Load Storess',
  LoadStoressSuccess = '[Stores] Load Storess Success',
  LoadStoressFailure = '[Stores] Load Storess Failure',
}

export class LoadStoress implements Action {
  readonly type = StoresActionTypes.LoadStoress;
}

export class LoadStoressSuccess implements Action {
  readonly type = StoresActionTypes.LoadStoressSuccess;
  constructor(public payload: { data: any }) { }
}

export class LoadStoressFailure implements Action {
  readonly type = StoresActionTypes.LoadStoressFailure;
  constructor(public payload: { error: any }) { }
}

export type StoresActions = LoadStoress | LoadStoressSuccess | LoadStoressFailure;

