<nav class="navbar fixed-top navbar-expand-md navbar-dark bg-green-hub-fbeeper px-0 px-xxl-1 py-0" style="z-index: 1000;">
    <div class="container-fluid px-1 px-lg-0">
      <!-- <div style="position: relative;">
        <div class="diagonal-box">
            <div class="content text-start"> Staging </div>
        </div>
      </div> -->
      <a class="navbar-brand py-0 me-0" role="button" routerLink="/">
        <img src="assets/images/Wajeez-icon-white.svg" alt="Wajeez" width="80px" height="35px">
        <p class="text-center mb-0"><b class="responsiveFont">{{currentUserLocation?.country_name}}</b></p>
      </a>
      <ul class="navbar-nav mb-2 mb-lg-0 responsiveHiddenLarge">
        <li class="nav-item d-flex align-items-center justify-content-center px-1 mt-2 mt-xl-0 pendingIndication" style="background-color: rgba(219, 29, 54, 0.65);"
            *ngIf="((currentActiveDeliveries?.pending | keyvalue)?.length || []) > 0">
          <h6 class="text-white mb-0 responsiveFont" style="font-weight: 600;">En attente: {{((currentActiveDeliveries?.pending || {}) | keyvalue)?.length || 0}}</h6>
        </li>
      </ul>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item justify-content-center border-start border-end mt-1 mt-md-0 pe-1" style="display: inline-flex;">
            <!-- <a class="nav-link active" aria-current="page" href="#">Home</a> -->
            <div class="d-flex align-items-center px-1">
              <img [src]="weather?.icon" [title]="weather?.temp + '°C ' + weather?.description" width="64px" height="50px">
            </div>
            <div class="d-flex align-items-center px-1 responsiveHiddenMedium">
              <div>
                <h3 class="mb-0 text-white text-center responsiveFont" ><b> {{weather?.temp}}°C</b></h3>
                <p class="mb-0 text-white text-center responsiveFont" ><b>{{weather?.description}}</b></p>
              </div>
            </div>
          </li>
          <li class="nav-item d-flex align-items-center justify-content-center mt-2 mt-xl-0 ps-2 pe-1">
            <h5 class="text-white mb-0" style="font-weight: 600; font-size: 1rem;">
              {{currentHub?.profile?.hub?.name}} 
              <!-- <span class="rounded-3 bg-white color-success-hub-fbeeper p-0 p-xxl-1 ms-2" style="font-size: 20px;" title="Nombre de visiteurs"> 
                {{(onlineVisitors?.online)?.toString().padStart(3, '0') || '000'}} *
              </span> -->
            </h5>
          </li>
          
          <ul class="d-flex align-items-center justify-content-center mt-2 mt-xl-0 ps-1">
            <!-- <li class="nav-item dropdown d-flex align-items-center px-1">
              <a class="btn rounded-3 dropdown-toggle p-1" [ngClass]="(currentHubManager?.received_sms || {} | keyvalue).length > 0? 'sms_retour':''" style="box-shadow: none; font-weight: 600; background-color: white; color: var(--success-hub-fbeeper) !important; font-size: 14px;"
                href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                SMS
              </a>
  
              <div class="dropdown-menu sms_dropdown" aria-labelledby="dropdownMenuLink">
                <table class="w-100" style="font-weight: 500; border-bottom: 1px solid #e9ecef;" 
                *ngFor="let msg of currentHubManager?.received_sms | keyvalue:otherService.onCompare">
                    <tbody>
                      <tr>
                        <td colspan="2" class="text-end py-1"><small>{{msg?.value?.timestamp | date: 'dd/MM/yyyy'}} {{msg?.value?.timestamp | date: 'HH:mm'}}</small></td>
                      </tr>
                      <tr>
                        <td class="py-1"><div class="text-start"><b>{{msg?.value?.client_name}}</b></div></td>
                        <td class="text-end py-1">{{msg?.value?.from}}</td>
                      </tr>
                      <tr>
                        <td colspan="2" class="py-1"> <div class="text-break"><b>msg: </b> {{msg?.value?.text}}</div></td>
                      </tr>
                      <tr>
                        <td colspan="2" class="text-start text-break py-1"><b>commande: </b> {{msg?.value?.last_order}}</td>
                      </tr>
                      <tr>
                        <td colspan="2" class="text-end pb-2">
                          <button class="btn btn-danger" (click)="removeSMSMsg(msg?.key)">Fermer</button>
                        </td>
                      </tr>
                    </tbody>
                </table>
                <table *ngIf="(currentHubManager?.received_sms || {} | keyvalue).length == 0"
                        class="w-100" style="font-weight: 500;">
                  <tbody>
                    <tr>
                      <td class="text-center">Aucun message.</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </li> -->
            <li class="nav-item d-flex align-items-center px-1">
              <div class="dropdown">
                <button class="btn btn-secondary dropdown-toggle p-1" type="button" data-bs-toggle="dropdown" aria-expanded="false" 
                        style="border: none; font-weight: 600; background-color: white; color: var(--success-hub-fbeeper) !important;">
                  Partition 
                  <span *ngIf="currentPartition == 1 || currentPartition == 3">
                    1 
                  </span>
                  <span *ngIf="currentPartition == 2 || currentPartition == 4">
                    2
                  </span>
                  <svg  *ngIf="currentPartition == 3 || currentPartition == 4" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="var(--danger-hub-fbeeper)" class="bi bi-eye-slash" viewBox="0 0 16 16">
                    <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z"/>
                    <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z"/>
                    <path d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708z"/>
                  </svg>
                </button>
                <ul class="dropdown-menu">
                  <li><span class="dropdown-item" (click)="changePartition(0)">Tous</span></li>
                  <li><span class="dropdown-item" (click)="changePartition(1)">Partition 1</span></li>
                  <li><span class="dropdown-item" (click)="changePartition(2)">Partition 2</span></li>
                  <li><span class="dropdown-item" (click)="changePartition(3)">Partition 1 et cacher 2</span></li>
                  <li><span class="dropdown-item" (click)="changePartition(4)">Partition 2 et cacher 1</span></li>
                </ul>
              </div>
            </li>
            <li class="nav-item d-flex align-items-center px-2 ps-xxl-5">
              <button class="btn rounded-pill fw-bold px-1 py-2" 
                      [ngClass]="currentHubManager?.accept_delivery ? 'btn-success-fbeeper' : 'bg-danger-fbeeper'"
                      (click)="currentHubManager?.accept_delivery ? (pauseHubModal = true) : (runHubModal = true)">
                {{currentHubManager?.accept_delivery ? 'Pause' : 'Démarrer'}}
              </button>
            </li>
            <li class="nav-item d-flex align-items-center px-1">
              <div class="dropdown">
                <button class="btn rounded-pill dropdown-toggle fw-bold px-1 py-2" style="font-size: 14px;" 
                        [ngClass]="{
                          'bg-danger-fbeeper': !(currentHubManager?.can_deliver),
                          'bg-warning text-white': currentHubManager?.can_deliver && !(currentHubManager?.delivery_zones_active?.length > 0),
                          'btn-success-fbeeper': currentHubManager?.can_deliver && (+currentHubManager?.delivery_zones_active?.length > 0)
                        }"
                        type="button" id="dropdownMenuDisponible" data-bs-toggle="dropdown" aria-expanded="false">
                  Disponible {{otherService.countZones(currentHubManager?.delivery_zones).active_Zones_count + '/' + otherService.countZones(currentHubManager?.delivery_zones).all_zones_count}}
                </button>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenuDisponible" style="width: 300px; overflow-x: auto;">
                  <li *ngFor="let zone of (currentHubManager?.delivery_zones | keyvalue:otherService.onCompare)">
                    <div class="dropdown-item ">
                      <div class="row">
                        <div class="col-3 fw-bold">
                          {{zone?.value?.name}}
                        </div>
                        <div class="col-6">
                          Livreurs actifs: <span style="color: var(--info-hub-fbeeper);"> {{zone?.value?.active_staff}} </span>
                        </div>
                        <div class="col-3 text-end">
                          <span *ngIf="!zone?.value?.available" title="">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="var(--danger-hub-fbeeper)" class="bi bi-pause-fill" viewBox="0 0 16 16">
                              <path d="M5.5 3.5A1.5 1.5 0 0 1 7 5v6a1.5 1.5 0 0 1-3 0V5a1.5 1.5 0 0 1 1.5-1.5zm5 0A1.5 1.5 0 0 1 12 5v6a1.5 1.5 0 0 1-3 0V5a1.5 1.5 0 0 1 1.5-1.5z"/>
                            </svg>
                          </span>
                          <span class="ms-2" *ngIf="!zone?.value?.can_deliver" title="Fermé">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="var(--danger-hub-fbeeper)" class="bi bi-x-circle" viewBox="0 0 16 16">
                              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                            </svg>
                          </span>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li *ngIf="(currentHubManager?.delivery_zones | keyvalue:otherService.onCompare)?.length == 0">
                    Aucune zone.
                  </li>
                </ul>
              </div>
            </li>
          </ul>
          <ul class="d-flex align-items-center justify-content-center mt-3 mt-xl-0 ps-1 ps-xxl-5">
            <li class="nav-item d-flex align-items-center px-1 pe-xxl-2" title="Temps moyen de livraison">
              <img src="assets/images/delivery-time-icon.png" alt="delivery-time-icon"  style="width: 25px; height: 25px;">
              <span class="px-1 px-xxl-2" style="color: #57fc00;">{{otherService.avgDeliveryTime(+currentHubManager?.avg_delivery_time * 1000) || "00m"}}</span>
            </li>
            <li class="nav-item d-flex align-items-center px-1 px-xxl-2" title="Temps moyen d'affectation">
              <img src="assets/images/affectation-time-icon.png" alt="affectation-time-icon" style="width: 25px; height: 25px;">
              <span class="px-1 px-xxl-2" style="color: #57fc00;">{{otherService.avgDeliveryTime(+currentHubManager?.avg_affectation_time * 1000) || "00m"}}</span>
            </li>
            <li class="nav-item d-flex align-items-center px-1 px-xxl-2" title="Trophée">
              <svg xmlns="http://www.w3.org/2000/svg"  style="width: 25px; height: 25px;" fill="white" class="bi bi-trophy-fill" viewBox="0 0 16 16">
                <path d="M2.5.5A.5.5 0 0 1 3 0h10a.5.5 0 0 1 .5.5c0 .538-.012 1.05-.034 1.536a3 3 0 1 1-1.133 5.89c-.79 1.865-1.878 2.777-2.833 3.011v2.173l1.425.356c.194.048.377.135.537.255L13.3 15.1a.5.5 0 0 1-.3.9H3a.5.5 0 0 1-.3-.9l1.838-1.379c.16-.12.343-.207.537-.255L6.5 13.11v-2.173c-.955-.234-2.043-1.146-2.833-3.012a3 3 0 1 1-1.132-5.89A33.076 33.076 0 0 1 2.5.5zm.099 2.54a2 2 0 0 0 .72 3.935c-.333-1.05-.588-2.346-.72-3.935zm10.083 3.935a2 2 0 0 0 .72-3.935c-.133 1.59-.388 2.885-.72 3.935z"/>
              </svg>
              <span class="px-1 px-xxl-2" style="color: #57fc00;">{{currentHubManager?.delivery_stats?.progress}}%</span>
            </li>
            <li class="nav-item d-flex align-items-center px-1 px-xxl-2" title="Courses libres">
              <svg *ngIf="currentHubManager?.can_errand" xmlns="http://www.w3.org/2000/svg" style="width: 25px; height: 25px;" fill="white" class="bi bi-cart4" viewBox="0 0 16 16">
                <path d="M0 2.5A.5.5 0 0 1 .5 2H2a.5.5 0 0 1 .485.379L2.89 4H14.5a.5.5 0 0 1 .485.621l-1.5 6A.5.5 0 0 1 13 11H4a.5.5 0 0 1-.485-.379L1.61 3H.5a.5.5 0 0 1-.5-.5zM3.14 5l.5 2H5V5H3.14zM6 5v2h2V5H6zm3 0v2h2V5H9zm3 0v2h1.36l.5-2H12zm1.11 3H12v2h.61l.5-2zM11 8H9v2h2V8zM8 8H6v2h2V8zM5 8H3.89l.5 2H5V8zm0 5a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0zm9-1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0z"/>
              </svg>
            </li>
          </ul>
        </ul>
        <ul class="navbar-nav mb-2 mb-lg-0">
          <li class="nav-item d-flex align-items-center justify-content-center px-1 mt-2 mt-xl-0 pendingIndication responsiveHiddenSmall" 
              style="background-color: rgba(219, 29, 54, 0.65);"
              *ngIf="
                ((currentActiveDeliveries?.pending | keyvalue)?.length || 0) 
                + ((currentActiveErrands?.pending | keyvalue)?.length || 0) 
                > 0">
            <h6 class="text-white mb-0" style="font-weight: 600;">
              Livraisons en attente: {{
                (((currentActiveDeliveries?.pending || {}) | keyvalue)?.length || 0)
                + (((currentActiveErrands?.pending || {}) | keyvalue)?.length || 0)}}
            </h6>
          </li>
          <li class="nav-item d-flex align-items-center justify-content-center px-1 px-xxl-3 mt-2 mt-xl-0 responsiveHiddenMedium">
            <h6 class="text-white mb-0" style="font-weight: 600;">{{currentUser?.profile?.name}}</h6>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle p-1" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-person-fill" viewBox="0 0 16 16">
                <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
              </svg>
            </a>
            <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
             <!--  <li><a class="dropdown-item" href="#">Another action</a></li>
              <li><hr class="dropdown-divider"></li> -->
              <li class="dropdown-item" style="color: var(--info-hub-fbeeper);">{{currentUser?.profile?.name}}</li>
              <li><span class="dropdown-item" role="button" (click)="confirmationModal = true">Se déconnecter</span></li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
</nav>

<!-- confirmation modal -->
<p-dialog header="Confirmation" [(visible)]="confirmationModal" [modal]="true" styleClass="dialog35" [baseZIndex]="10000" [draggable]="false" [resizable]="false" [closable]="true">
  <div class="row">
      <div class=" offset-md-1 offset-lg-1 col-sm-10 col-md-10 col-lg-10">
          <p class="mb-0">Êtes-vous sûr de vouloir vous déconnecter?</p>
      </div>
  </div>
  <div class="row mt-4">
      <div class="col-sm-12 col-md-12 col-lg-12 text-end">
          <button class="btn btn-default-fbeeper fw-bold mr-3" (click)="confirmationModal = false;">Non</button>
          <button type="submit" class="btn btn-danger fw-bold" (click)="logout()">Oui</button>
      </div>
  </div>
</p-dialog>

<!-- Pause hub modal -->
<p-dialog header="Fermeture du Hub" [(visible)]="pauseHubModal" [modal]="true" styleClass="dialog35" [baseZIndex]="10000" 
        [draggable]="false" [resizable]="false" [closable]="true" (onShow)="initPauseHubForm()">
  <p-toast key="toast1" [hideTransitionOptions]="'10ms'"></p-toast>
  <form [formGroup]="pauseHubForm" (ngSubmit)="submitPauseHub(btnSPH)">
    <div class="row mt-2">
      <!-- <div class="col-3 d-flex align-items-center">
        <label style="color: var(--success-hub-fbeeper)">Justification: <span class="color-danger-hub-fbeeper">*</span> </label>
      </div> -->
      <div class="col-6">
        <div class="form-group">
          <label style="color: var(--success-hub-fbeeper)">Justification: <span class="color-danger-hub-fbeeper">*</span> </label>
          <select class="form-select" aria-label="Default select" placeholder="Selectionner la cause" formControlName="pause_reason">
            <option [ngValue]="null" [attr.disabled]="true">Selectionner la cause</option>
            <option value="technical_problem">Problème technique</option>
            <option value="bad_weather">Mauvais temps</option>
            <option value="closed">Fermé</option>
            <option value="service en surcharge">Service en surcharge</option>
          </select>
          <small class="form-text text-danger" *ngIf="pauseHubForm.controls.pause_reason.invalid && pauseHubForm.controls.pause_reason.touched && pauseHubForm.controls.pause_reason.errors['required']">La justification est obligatoire</small>
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-12">
        <div class="form-group">
          <label style="color: var(--success-hub-fbeeper)">Retour: <span class="color-danger-hub-fbeeper">*</span> </label><br>
          <div class="form-check form-check-inline mt-1">
            <input class="form-check-input" type="radio" formControlName="accept_delivery_at" id="accept_delivery_at_10m" value="10m">
            <label class="form-check-label" for="accept_delivery_at_10m">10 minutes</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" formControlName="accept_delivery_at" id="accept_delivery_at_30m" value="30m">
            <label class="form-check-label" for="accept_delivery_at_30m">30 minutes</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" formControlName="accept_delivery_at" id="accept_delivery_at_1h" value="1h">
            <label class="form-check-label" for="accept_delivery_at_1h">1 heure</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" formControlName="accept_delivery_at" id="accept_delivery_at_3h" value="3h">
            <label class="form-check-label" for="accept_delivery_at_3h">3 heures</label>
          </div>
        </div>
        <div class="form-group mt-2">
          <div class="form-check form-check-inline d-flex align-items-center">
            <input class="form-check-input me-2" type="radio" formControlName="accept_delivery_at" id="accept_delivery_at_today" value="today">
            <label class="form-check-label" for="accept_delivery_at_today">Aujourd'hui</label>
            <p-calendar [style]="{width: '150px'}" formControlName="accept_delivery_at_time" [stepMinute]="15" [timeOnly]="true" [inline]="true"></p-calendar>
          </div>
        </div>
        <div class="form-group mt-2">
          <div class="form-check form-check-inline d-flex align-items-center">
            <input class="form-check-input me-2" type="radio" formControlName="accept_delivery_at" id="accept_delivery_at_1d" value="24h">
            <label class="form-check-label" for="accept_delivery_at_1d">Demain</label>
            <p-calendar [style]="{width: '150px'}" formControlName="accept_delivery_at_time" [stepMinute]="15" [timeOnly]="true" [inline]="true"></p-calendar>
          </div>
        </div>
        <div class="form-group mt-2">
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" formControlName="accept_delivery_at" id="accept_delivery_at_Indefinitely" value="null">
            <label class="form-check-label" for="accept_delivery_at_Indefinitely">Indéfiniment</label>
          </div>
        </div>
        <small class="form-text text-danger" *ngIf="pauseHubForm.controls.accept_delivery_at.invalid && pauseHubForm.controls.accept_delivery_at.touched && pauseHubForm.controls.accept_delivery_at.errors['required']">La date de retour est obligatoire</small>
      </div>
    </div>

    <div class="row mt-4">
        <div class="col-sm-12 col-md-12 col-lg-12 text-end">
            <button class="btn fw-bold me-3" style="color: var(--gray-hub-fbeeper);" (click)="pauseHubModal = false;">Annuler</button>
            <button type="submit" class="btn btn-success-fbeeper fw-bold" style="background-color: var(--success-hub-fbeeper);" #btnSPH> 
              <div *ngIf="btnSPH.disabled && pauseHubForm.valid" 
                  class="spinner-border" 
                  style="height: 1rem !important; width: 1rem !important; margin-right: 5px;" 
                  role="status">
              </div>
              Envoyer
            </button>
        </div>
    </div>
  </form>
</p-dialog>

<!-- run hub confirmation modal -->
<p-dialog header="Confirmation" [(visible)]="runHubModal" [modal]="true" styleClass="dialog35" [baseZIndex]="10000" [draggable]="false" [resizable]="false" [closable]="true">
  <p-toast key="toast1" [hideTransitionOptions]="'10ms'"></p-toast>
  <div class="row">
      <div class=" offset-md-1 offset-lg-1 col-sm-10 col-md-10 col-lg-10">
          <p class="mb-0">Êtes-vous sûr de vouloir démarrer le hub?</p>
      </div>
  </div>
  <div class="row mt-4">
      <div class="col-sm-12 col-md-12 col-lg-12 text-end">
          <button class="btn btn-default-fbeeper fw-bold mr-3" (click)="runHubModal = false;">Non</button>
          <button type="submit" class="btn btn-danger fw-bold" #btnSRunHub (click)="runHubSubmit(btnSRunHub)">Oui</button>
      </div>
  </div>
</p-dialog>