import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { Action } from '@ngrx/store';
import * as storesActions from './stores.actions';
import { FirebaseService } from 'src/app/services/firebase.service';
import { mergeMap, map, catchError } from 'rxjs/operators';



@Injectable()
export class StoresEffects {



  constructor(
    private actions$: Actions,
    private firebaseService: FirebaseService,
  ) { }

  loadStores$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(storesActions.StoresActionTypes.LoadStoress),
      mergeMap(
        action => this.firebaseService.getStatusStores().valueChanges().pipe(
          map(stores => (new storesActions.LoadStoressSuccess({data: stores}))),
          catchError(err => of(new storesActions.LoadStoressFailure({error: err})))
        )
      )
    )
  )
}
