import { Injectable } from '@angular/core';
import { AngularFireDatabase, AngularFireList, AngularFireObject } from '@angular/fire/compat/database';
import { select, Store } from '@ngrx/store';
import * as fromUser from 'src/app/ngrx/user/user.selectors'
import * as fromHub from 'src/app/ngrx/hub/hub.selectors'

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {

  currentUser: any = {}
  currentHub: any = {}
  onlineVisitors: AngularFireObject<any> = <any>{};

  constructor(
    private angularFireDB: AngularFireDatabase,
    private store: Store,
  ) { 

    this.store.pipe(select(fromUser.getCurrentUser)).subscribe(
      (user: any) =>{
        this.currentUser = user;
      }
    )

    this.store.pipe(select(fromHub.getCurrentHub)).subscribe(
      (user: any) =>{
        this.currentHub = user;
      }
    )
    
  }

  getFirebaseConnection(): AngularFireObject<any> {
    return this.angularFireDB.object("/.info/connected/");
  }

  getOnlineVisitors(hub_id: number): AngularFireObject<any> {
    return this.angularFireDB.object("/online_visitors/hub_id_" + hub_id);;
  }

  getStatus(region_id: any, hub_id: any): AngularFireObject<any> {
    return this.angularFireDB.object("/status/" + region_id + "/" + hub_id);
  }

  getActiveDeliveries(region_id: any, hub_id: any): AngularFireObject<any> {
    return this.angularFireDB.object("/active_deliveries/" + region_id + "/" + hub_id);
  }

  getOnlineStoresByRegion(region_id: any): AngularFireObject<any> {
    return this.angularFireDB.object("/online/" + region_id + "/stores/");
  }

  getSMSSendStatus(): AngularFireList<any> {
    return this.angularFireDB.list("/sms_send/status");
  }

  getSMSSendReceivedSMS(): AngularFireObject<any> {
    return this.angularFireDB.object("/sms_send/received_sms");
  }

  removeHubManagerReceivedSMS(region_id: any, hub_id: any, key: any){
    this.angularFireDB.list("/status/" + region_id + "/" + hub_id + "/hub_manager/received_sms/" + key).remove();
  }

  /* getStatusHubManagerZones(region_id: any, hub_id: any): AngularFireList<any> {
    return this.angularFireDB.list("/status/" + region_id + "/" + hub_id + "/hub_manager/delivery_zones/");
  } */

  getStatusDelivery(): AngularFireObject<any> {
    return this.angularFireDB.object("/status/" + this.currentHub?.profile?.hub?.region?.id + "/" + this.currentHub?.profile?.hub?.id + "/delivery/");
  }

  getStatusDeliveryPositions(): AngularFireObject<any> {
    return this.angularFireDB.object("/status/" + this.currentHub?.profile?.hub?.region?.id + "/" + this.currentHub?.profile?.hub?.id + "/delivery_positions/");
  }

  getStatusHubManager(): AngularFireObject<any> {
    return this.angularFireDB.object("/status/" + this.currentHub?.profile?.hub?.region?.id + "/" + this.currentHub?.profile?.hub?.id + "/hub_manager/");
  }

  getStatusStores(): AngularFireObject<any> {
    return this.angularFireDB.object("/status/" + this.currentHub?.profile?.hub?.region?.id + "/" + this.currentHub?.profile?.hub?.id + "/stores/");
  }

  getActiveErrands(region_id: any = this.currentHub?.profile?.hub?.region?.id, hub_id: any = this.currentHub?.profile?.hub?.id): AngularFireObject<any> {
    return this.angularFireDB.object("/active_errands/" + region_id + "/" + hub_id);
  }

}
