import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { FirebaseService } from './services/firebase.service';
import { GlobalInjectableService } from './services/global-injectable.service';
import localeFr from '@angular/common/locales/fr';
import { CommonModule, registerLocaleData } from '@angular/common';
import { FilterService } from 'primeng/api';
import { SharedModule } from './shared/shared.module';
import { ToastModule } from 'primeng/toast';
import { initializeApp } from 'firebase/app';
import { environment } from 'src/environments/environment';
import { onValue } from "firebase/database";
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    imports: [
      CommonModule,
      SharedModule,
      ToastModule,
      RouterModule
    ]
})
export class AppComponent {

  title = 'Wajeez Hub';
  showSplash: boolean = true;
  
  
  constructor(
    private router: Router,
    private firebaseService: FirebaseService,
    private globalInjectableService: GlobalInjectableService,
    private filterService: FilterService
  ){
    console.log("app component");
    registerLocaleData(localeFr, 'fr');
  }
//
  async ngOnInit() {
    //await this.getCurrentCountry();
    console.log("app component");
    <any>window.addEventListener('storage', (event) => {
      if (event.storageArea == localStorage) {
        let token_auth_agent = localStorage.getItem('token_auth_agent');
        let sub_token_hub_agent = localStorage.getItem('sub_token_hub_agent');
        if((token_auth_agent == undefined) || (sub_token_hub_agent == undefined)) { // you can update this as per your key
            // DO LOGOUT FROM THIS TAB AS WELL
            //this.router.navigate(['/']); 
            this.router.navigateByUrl("/login", { skipLocationChange: true });
        }
      }
    }, false);

    this.firebaseService.getFirebaseConnection().subscribe(
      (data: any) => {
        this.globalInjectableService.changeIsFirebaseConnected(data)
      }
    );

    /* onValue(this.firebaseService.getFirebaseConnection(), (snap: any) => {
      if (snap.val() === true) {
        this.globalInjectableService.changeIsFirebaseConnected(snap.val())
      } else {
        this.globalInjectableService.changeIsFirebaseConnected(snap.val())
      }
    }); */

    this.globalInjectableService.currentIsSplashScreen.subscribe(
      (data: any) => {
        //console.log('app component showSplash', data)
        this.showSplash = data;
      }
    )

    /* this.config.setTranslation({
      emptyMessage: "Aucun résultat trouvé",
      emptyFilterMessage: "Aucun résultat trouvé - Filtre",
      choose: "Choisir",
      upload: "Télécharger",
      cancel: "Annuler",
      startsWith: "Commence avec",
      contains: "Contient",
      notContains: "Ne contient pas",
      endsWith: "Se termine par",
      equals: "Équivaut à",
      notEquals: "Pas égal",
      clear: "Dégager",
      apply: "Appliquer",
      matchAll: "Faire correspondre tout",
      matchAny: "Correspondre à n'importe quel",
      addRule: "Ajouter une règle",
      removeRule: "Supprimer la règle",
      accept: "Oui",
      reject: "Non",
      dateIs: "La date est",
      dateIsNot: "La date n'est pas",
      dateBefore: "Date est avant",
      dateAfter: "Date est après",
    }); */

  }

}
