import { createFeatureSelector, createSelector } from '@ngrx/store';

import { State } from './user-location.reducer'

export const selectFeature = createFeatureSelector<State>('userLocation')

export const getCurrentUserLocation = createSelector(
    selectFeature,
    (state: any) => state.userLocation
);

export const getCurrentError = createSelector(
    selectFeature,
    (state: any) => state.error
);