import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { DeliveryEffects } from './status/delivery/delivery.effects';
import { StoresEffects } from './status/stores/stores.effects';
import { HubManagerEffects } from './status/hub-manager/hub-manager.effects';
import { UserLocationEffects } from './user-location/user-location.effects';
import { AtiveErrandsEffects } from './active-errands/ative-errands.effects';
import { StaffEffects } from './staff/staff.effects';
import { DeliveryPositionsEffects } from './status/delivery-positions/delivery-positions.effects';



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    EffectsModule.forFeature([UserLocationEffects, DeliveryEffects, HubManagerEffects, StoresEffects, AtiveErrandsEffects, StaffEffects, DeliveryPositionsEffects])
  ]
})
export class NgrxModule { }
