import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { FirebaseService } from 'src/app/services/firebase.service';
import * as DeliveryPositionsActions from './delivery-positions.actions'
import { Observable, catchError, mergeMap, map, of } from 'rxjs';
import { Action } from '@ngrx/store';



@Injectable()
export class DeliveryPositionsEffects {


  constructor(
    private actions$: Actions,
    private firebaseService: FirebaseService,
  ) { }

  getDeliveryPositions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeliveryPositionsActions.LoadDeliveryPositions),
      mergeMap(
        () => {
          return new Observable<Action>((observer) => {
            this.firebaseService.getStatusDeliveryPositions().query.on('child_added', (childSnapshot, prevChildKey) => {
              const deliveryKey = childSnapshot.key;
              const deliveryData = childSnapshot.val();
              observer.next(DeliveryPositionsActions.LoadAddedDeliveryPositionsSuccess({ key: deliveryKey, data: deliveryData }));
            });
            this.firebaseService.getStatusDeliveryPositions().query.on('child_changed', (childSnapshot, prevChildKey) => {
              const deliveryKey = childSnapshot.key;
              const deliveryData = childSnapshot.val();
              observer.next(DeliveryPositionsActions.LoadChangedDeliveryPositionsSuccess({ key: deliveryKey, data: deliveryData }));
            });
            this.firebaseService.getStatusDeliveryPositions().query.on('child_removed', (childSnapshot, prevChildKey) => {
              const deliveryKey = childSnapshot.key;
              const deliveryData = childSnapshot.val();
              observer.next(DeliveryPositionsActions.LoadRemovedDeliveryPositionsSuccess({ key: deliveryKey, data: deliveryData }));
            });
          }).pipe(
            catchError(err => of(DeliveryPositionsActions.LoadDeliveryPositionsFailure({ error: err })))
          );
        }
      )
    )
  )
}
