<p-toast key="KeyHub" [breakpoints]="{'920px': {width: '100%', right: '0', left: '0'}}" [baseZIndex]="2000"></p-toast>
<section>
    <app-header></app-header>
</section>

<section class="container-fluid px-0" style="margin-top: 53px;">
    <div class="d-flex h-100" id="wrapper">
        <!-- Sidebar -->
        <div>
            <div id="myOverlay" [ngClass]="open_sidenav ? 'overlay' : ''">
                <span *ngIf="open_sidenav" class="closebtn" title="Close Overlay"
                    (click)="open_sidenav = !open_sidenav">x</span>
                <div class="overlay-content h-100">
                    <app-sidenav [sidenav_hidden]="!open_sidenav"></app-sidenav>
                </div>
            </div>
        </div>

        <!-- /#sidebar-wrapper -->

        <!-- Page Content -->
        <div id="page-content-wrapper" class="w-100">
            <nav class="navbar navbar-expand-lg navbar-light">
                <div class="container-fluid">
                    <!-- <div class="collapse navbar-collapse" id="sidenav"></div>
                <button class="navbar-toggler"  type="button" data-bs-toggle="collapse" data-bs-target="#sidenav" aria-controls="sidenav" aria-expanded="false" aria-label="Toggle navigation"> </button> -->
                    <button class="navbar-toggler" type="button" (click)="open_sidenav = !open_sidenav">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                            class="bi bi-chevron-double-right" viewBox="0 0 16 16">
                            <path fill-rule="evenodd"
                                d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708z" />
                            <path fill-rule="evenodd"
                                d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708z" />
                        </svg>
                    </button>
                </div>
            </nav>
            <div class="container-fluid">
                <!-- Main -->
                <div class="row mt-2" *ngIf="!isFirebaseConnected">
                    <div class="col-12">
                        <p-message class="w-100" severity="error" text="Vous êtes hors connexion!"></p-message>
                    </div>
                </div>
                <div class="row mt-2" *ngIf="currentHubHubManager?.accept_delivery_at">
                    <div class="col-12">
                        <p-message class="w-100" severity="warn"
                            [text]="'La livraision est en pause jusqu\'au ' + (currentHubHubManager?.accept_delivery_at?.date | date: 'dd/MM/yyyy HH:mm')"></p-message>
                    </div>
                </div>
                <p-progressBar class="mb-1" mode="indeterminate" *ngIf="loader"></p-progressBar>
                <router-outlet></router-outlet>
                <!-- End main -->
            </div>
        </div>
        <!-- /#page-content-wrapper -->
    </div>
</section>