import { Action } from '@ngrx/store';
import { UserActions, UserActionTypes } from './user.actions';


export const userFeatureKey = 'user';

export interface State {

}

export const initialState: State = {
  user: <any>{},
  error: ''
};

export function reducer(state = initialState, action: UserActions): State {
  switch (action.type) {

    case UserActionTypes.LoadUsers:
      return {
        ...state
      }

    case UserActionTypes.LoadUsersSuccess:
      return {
        ...state,
        user: action.payload.data,
        error: '',
      }

    case UserActionTypes.LoadUsersFailure:
      return {
        ...state,
        user: <any>{},
        error: action.payload.error,
      }

    default:
      return state;
  }
}
