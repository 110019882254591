import { Action } from '@ngrx/store';
import { StatusActions, StatusActionTypes } from './status.actions';


export const statusFeatureKey = 'status';

export interface State {

}

export const initialState: State = {
  status: <any>{},
  error: <any>''
};

export function reducer(state = initialState, action: StatusActions): State {
  switch (action.type) {

    case StatusActionTypes.LoadStatuss:
      return {
        ...state
      }

    case StatusActionTypes.LoadStatussSuccess:
      let status: any = {...action.payload.data}
      status.delivery_zones_active = Object.entries(status?.hub_manager?.delivery_zones || []).filter(([key, value]: any) => (value?.can_deliver == 1 && value?.available == 1));
      
      return {
        ...state,
        status: status,
        error: '',
      }

    case StatusActionTypes.LoadStatussFailure:
      return {
        ...state,
        status: <any>{},
        error: action.payload.error,
      }

    default:
      return state;
  }
}
