import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SplashScreenComponent } from './splash-screen/splash-screen.component';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { DonutLoaderComponent } from './donut-loader/donut-loader.component';
import { LoderCheckmarkComponent } from './loder-checkmark/loder-checkmark.component';
import { HeaderComponent } from './header/header.component';
import { DialogModule } from 'primeng/dialog';
import {RouterModule} from '@angular/router';
import { ReactiveFormsModule,FormsModule } from '@angular/forms';
import {CalendarModule} from 'primeng/calendar';
import {ToastModule} from 'primeng/toast';
import { SidenavComponent } from './sidenav/sidenav.component';
import { AdditionalPhonesComponent } from './additional-phones/additional-phones.component'
import { TableModule } from 'primeng/table';
import { OrderSideBarComponent } from './order-side-bar/order-side-bar.component';
import { PipeTransformerModule } from '../pipe-transformer/pipe-transformer.module';
import { SidebarModule } from 'primeng/sidebar';
import { GMapModule } from 'primeng/gmap';
import { HistoryPanelComponent } from './history-panel/history-panel.component';
import { PaginatorModule } from 'primeng/paginator';
import {RatingModule} from 'primeng/rating';
import { StoresTableComponent } from './stores-table/stores-table.component';
import { DropdownAdditionalPhonesComponent } from './dropdown-additional-phones/dropdown-additional-phones.component';
import { DeliveriesTableComponent } from './deliveries-table/deliveries-table.component';
import { TooltipModule } from 'primeng/tooltip';
import { WorkingHoursComponent } from './working-hours/working-hours.component';
import { WeekdaysPipe } from '../pipe-transformer/weekdays.pipe';
import { ProgressBarModule } from 'primeng/progressbar';
import { WorkingHoursTableComponent } from './working-hours-table/working-hours-table.component';
import { SearchStaffComponent } from './search-staff/search-staff.component';
import { StoreTicketComponent } from './store-ticket/store-ticket.component';
import { CarouselModule } from 'primeng/carousel';
import { ImageModule } from 'primeng/image';
import { ErrandsPanelComponent } from './errands-panel/errands-panel.component';
import { SendSmsComponent } from './send-sms/send-sms.component';
import { DropdownModule } from 'primeng/dropdown';
import { ClientTicketComponent } from './client-ticket/client-ticket.component';

@NgModule({
  declarations: [
    SplashScreenComponent,
    DonutLoaderComponent,
    LoderCheckmarkComponent,
    HeaderComponent,
    SidenavComponent,
    AdditionalPhonesComponent,
    OrderSideBarComponent,
    HistoryPanelComponent,
    StoresTableComponent,
    DropdownAdditionalPhonesComponent,
    DeliveriesTableComponent,
    WorkingHoursComponent,
    WorkingHoursTableComponent,
    SearchStaffComponent,
    StoreTicketComponent,
    ErrandsPanelComponent,
    SendSmsComponent,
    ClientTicketComponent
  ],
  imports: [
    CommonModule,
    ProgressBarModule,
    MessagesModule,
    MessageModule,
    DialogModule,
    CalendarModule,
    FormsModule,
    ToastModule,
    TableModule,
    PipeTransformerModule,
    SidebarModule,
    GMapModule,
    PaginatorModule,
    RatingModule,
    TooltipModule,
    ReactiveFormsModule,
    CarouselModule,
    ImageModule,
    DropdownModule,
    RouterModule
  ],
  exports: [
    SplashScreenComponent,
    DonutLoaderComponent,
    LoderCheckmarkComponent,
    HeaderComponent,
    SidenavComponent,
    OrderSideBarComponent,
    AdditionalPhonesComponent,
    HistoryPanelComponent,
    StoresTableComponent,
    DropdownAdditionalPhonesComponent,
    DeliveriesTableComponent,
    WorkingHoursTableComponent,
    SearchStaffComponent,
    StoreTicketComponent,
    ErrandsPanelComponent,
    SendSmsComponent,
    ClientTicketComponent
  ]

})
export class SharedModule { }
