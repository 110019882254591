import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {

  constructor() { }

  getUrl() {
    return environment.apiUrl;
  }

  getBaseUrl() {
    return environment.baseApiUrl;
  }

  getDynamicUrl(){
    return environment.dynamicApiUrl;
  }
}
