import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared/shared.module';
import { JwtModule } from "@auth0/angular-jwt";

import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { HubComponent } from './hub/hub.component';
import { ErrorOccurredComponent } from './error-occurred/error-occurred.component';
import { FatalErrorComponent } from './fatal-error/fatal-error.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { HubsMonitorComponent } from './hubs-monitor/hubs-monitor.component'
import { CountriesComponent } from './countries/countries.component';

import { NgrxModule } from './ngrx/ngrx.module';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { reducers, metaReducers } from './reducers';


import { MessageService } from 'primeng/api';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { ToastModule } from 'primeng/toast';
import { DialogModule } from 'primeng/dialog';
import {ButtonModule} from 'primeng/button';
import {TableModule} from 'primeng/table';
import {ProgressBarModule} from 'primeng/progressbar';
import {PaginatorModule} from 'primeng/paginator';
import {BlockUIModule} from 'primeng/blockui';

import { AngularFireModule } from "@angular/fire/compat";
import { AngularFireAuthModule } from "@angular/fire/compat/auth";
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging'
import { AngularFirePerformanceModule, PerformanceMonitoringService } from '@angular/fire/compat/performance';

import { AuthMultinationalService } from './authentication/auth-multinational.service';
import { environment } from '../environments/environment';
import { ClientsTicketsComponent } from './clients-tickets/clients-tickets.component';
import { MultiSelectModule } from 'primeng/multiselect';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HubComponent,
    ErrorOccurredComponent,
    FatalErrorComponent,
    PageNotFoundComponent,
    HubsMonitorComponent,
    CountriesComponent,
    ClientsTicketsComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    JwtModule,
    ReactiveFormsModule,
    SharedModule,
    MessagesModule,
    MessageModule,
    ToastModule,
    DialogModule,
    ButtonModule,
    TableModule,
    PaginatorModule,
    ProgressBarModule,
    BlockUIModule,
    MultiSelectModule,
    
    StoreModule.forRoot(reducers, { metaReducers }),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    NgrxModule,
    EffectsModule.forRoot([]),

    AngularFireAuthModule,
    AngularFireDatabaseModule,
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),

    !environment.production ? AngularFirePerformanceModule : [],

    AppRoutingModule,
  ],
  providers: [
    MessageService,
    AuthMultinationalService,
    {
        provide: APP_INITIALIZER,
        useFactory: (authMultinationalService: AuthMultinationalService) =>
            () => authMultinationalService.getCurrentCountry(),
        deps: [AuthMultinationalService],
        multi: true
    },
    !environment.production ? PerformanceMonitoringService : [],
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
