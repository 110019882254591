import { createFeatureSelector, createSelector } from '@ngrx/store';


import { State } from './active-deliveries.reducer'

const getActiveDeliveriesFeatureState = createFeatureSelector<State>('activeDeliveries')

export const getCurrentActiveDeliveries = createSelector(
    getActiveDeliveriesFeatureState,
    (state: any) => state.activeDeliveries
)

export const getCurrentError = createSelector(
    getActiveDeliveriesFeatureState,
    (state: any) => state.error
)