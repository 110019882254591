import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { MessageService } from 'primeng/api';
import { Observable, catchError, mergeMap, map, of } from 'rxjs';
import * as StaffActions from './staff.actions'
import { StaffService } from 'src/app/services/staff.service';



@Injectable()
export class StaffEffects {

  constructor(
    private actions$: Actions,
    public staffService: StaffService,
    private messageService: MessageService,
  ) {}

  getStaff$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StaffActions.loadStaffs),
      mergeMap(async () =>{
        let getStaffList = <any>{}
        getStaffList = await this.staffService.filterStaff("type:delivery&order_by=-active").pipe(
          map((staff: any) => {
            let setting: any = [... staff?.data]
            return StaffActions.loadStaffsSuccess({ data: setting })
          }),
          catchError(err => of(StaffActions.loadStaffsFailure({error: JSON.stringify(err)})))
        ).toPromise()
        .catch((err: any) => {
          this.messageService.clear();
          this.messageService.add({key: 'KeyHome', severity:'error', summary: 'Erreur', detail: JSON.stringify(err)});
          of(StaffActions.loadStaffsFailure({ error: JSON.stringify(err)}))
          }
        );
        return getStaffList;
      })
    )
  )
}
