import { createAction, props } from '@ngrx/store';

export const loadUserLocations = createAction(
  '[UserLocation] Load UserLocations'
);

export const loadUserLocationsSuccess = createAction(
  '[UserLocation] Load UserLocations Success',
  props<{ data: any }>()
);

export const loadUserLocationsFailure = createAction(
  '[UserLocation] Load UserLocations Failure',
  props<{ error: any }>()
);
