import { Injectable } from '@angular/core';
import { HttpserviceService } from './httpservice.service';

@Injectable({
  providedIn: 'root'
})
export class RegionsService {

  constructor(private httpserviceService: HttpserviceService) { }

  getWeatherByRegion(regionId: number, params = ""){
    return this.httpserviceService.get("region/" + regionId +"/weather/?" + params);
  }

  getRegionById(regionId: number){
    return this.httpserviceService.get("region/" + regionId);
  }

}
