import { Injectable } from '@angular/core';
import { HttpserviceService } from './httpservice.service';

@Injectable({
  providedIn: 'root'
})
export class HubService {

  constructor(private httpserviceService: HttpserviceService) { }

  updateHub(data: any, id: any) {
    return this.httpserviceService.post(data, "hub/" + id);
  }
}
