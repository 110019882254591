import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { GlobalInjectableService } from '../services/global-injectable.service';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class NoAuthGuard implements CanActivate {
  constructor(
    public auth: AuthService, 
    public router: Router,
    private globalInjectableService: GlobalInjectableService
  ) {}


  canActivate(route: ActivatedRouteSnapshot,state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    this.globalInjectableService.changeIsSplashScreen(true);
    return new Promise((resolve) => {
      this.auth.isAuthenticated().then(
        (verify) => {
          //console.log('home auth', verify)
          if(verify){
            this.router.navigateByUrl('/');
            resolve(false);
          }else{
            resolve(true);
          }
        })
        .catch(err => {
          resolve(true);
        });
    })
  }
  
}
