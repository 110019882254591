import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { FirebaseService } from '../services/firebase.service';
import { GlobalInjectableService } from '../services/global-injectable.service';
import * as StatusActions from 'src/app/ngrx/status/status.actions'
import * as StoresActions from 'src/app/ngrx/status/stores/stores.actions'
import * as DeliveryActions from 'src/app/ngrx/status/delivery/delivery.actions'
import * as HubManagerActions from 'src/app/ngrx/status/hub-manager/hub-manager.actions'
import * as OnlineActions from 'src/app/ngrx/online/online.actions'
import * as ActiveDeliveriesActions from 'src/app/ngrx/active-deliveries/active-deliveries.actions'
import * as ActiveErrandsActions from 'src/app/ngrx/active-errands/active-errands.actions'
import * as fromHub from 'src/app/ngrx/hub/hub.selectors'
import * as fromStatus from 'src/app/ngrx/status/status.selectors'
import * as fromHubManager from 'src/app/ngrx/status/hub-manager/hub-manager.selectors'
import { Title } from '@angular/platform-browser';
import { Subject } from 'rxjs';
import * as StaffActions from 'src/app/ngrx/staff/staff.actions'
import * as DeliveryPositionsActions from 'src/app/ngrx/status/delivery-positions/delivery-positions.actions'
@Component({
  selector: 'app-hub',
  templateUrl: './hub.component.html',
  styleUrls: ['./hub.component.scss']
})
export class HubComponent implements OnInit {

  destroy$: Subject<boolean> = new Subject<boolean>();
  loader: boolean = false;
  currentHub: any = {}
  //currentHubStatus: any = {}
  currentHubHubManager: any = {};

  open_sidenav: boolean = false;

  constructor(
    private firebaseService: FirebaseService,
    private globalInjectableService: GlobalInjectableService,
    private store: Store,
    private titleService: Title,
  ) { }

  ngOnInit(): void {

    this.loader = true;

    this.store.pipe(select(fromHub.getCurrentHub), takeUntil(this.destroy$)).subscribe(
      (hub: any) =>{
        this.currentHub = hub;
        this.titleService.setTitle(this.currentHub?.profile?.hub?.name);
        this.subscribeToFirebase(this.currentHub);
      }
    )

    this.store.pipe(select(fromHubManager.getCurrentHubManager), takeUntil(this.destroy$)).subscribe(
      (data: any) => {
        this.currentHubHubManager = data;
      }
    )

    this.store.dispatch(StaffActions.loadStaffs());
    /* this.store.pipe(select(fromStatus.getCurrentStatus), takeUntil(this.destroy$)).subscribe(
      (data: any) => {
        this.currentHubStatus = data;
      }
    ) */
  }

  isFirebaseConnected: any;
  subscribeToFirebase(hub_data: any){
    let isStatus: any; let isActiveDeliveries: any; let isOnlineStoresByRegion: any; let isFirebaseConnected: any; let isActiveErrands: any;

    isStatus = new Promise((resolve) => {
      this.store.dispatch(new StoresActions.LoadStoress());
      this.store.dispatch(new DeliveryActions.LoadDeliverys());
      this.store.dispatch(new HubManagerActions.LoadHubManagers());
      this.store.dispatch(DeliveryPositionsActions.LoadDeliveryPositions());
      resolve(true);
    });

    isActiveDeliveries = new Promise((resolve) => {
      this.firebaseService.getActiveDeliveries(hub_data?.profile?.hub?.region?.id, hub_data?.profile?.hub?.id).valueChanges().pipe(takeUntil(this.destroy$)).subscribe(
        (data: any) => {
          this.store.dispatch(new ActiveDeliveriesActions.LoadActiveDeliveriessSuccess({data: data}));
          resolve(true);
        }
      );
    });

    isActiveErrands = new Promise((resolve) => {
      this.firebaseService.getActiveErrands().valueChanges().pipe(takeUntil(this.destroy$)).subscribe(
        (data: any) => {
          this.store.dispatch(ActiveErrandsActions.loadActiveErrandssSuccess({data: data}));
          resolve(true);
        }
      );
    });

    isOnlineStoresByRegion = new Promise((resolve) => {
      this.firebaseService.getOnlineStoresByRegion(hub_data?.profile?.hub?.region?.id).valueChanges().pipe(takeUntil(this.destroy$)).subscribe(
        (data: any) => {
          this.store.dispatch(new OnlineActions.LoadOnlinesSuccess({data: data}));
          resolve(true);
        }
      );
    });

    isFirebaseConnected = new Promise((resolve) => {
      this.globalInjectableService.currentIsFirebaseConnected.pipe(takeUntil(this.destroy$)).subscribe(
        (data: any) => {
          this.isFirebaseConnected = data;
          if(data){
            resolve(true);
          }
        }
      );
    });

    Promise.all([isStatus, isActiveDeliveries]).then((values) => {
      if(values[0] && values[1]){
        this.loader = false;
      }
    })
  }

  ngOnDestroy() {
    this.titleService.setTitle("Wajeez hub");
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

}
