import { createAction, props } from '@ngrx/store';

export const LoadDeliveryPositions = createAction(
  '[DeliveryPositions] Load DeliveryPositions'
);

export const LoadDeliveryPositionsSuccess = createAction(
  '[DeliveryPositions] Load DeliveryPositions Success',
  props<{ data: any }>()
);

export const LoadAddedDeliveryPositionsSuccess = createAction(
  '[DeliveryPositions] Load AddedDeliveryPositions Success',
  props<{ key: any, data: any }>()
);

export const LoadChangedDeliveryPositionsSuccess = createAction(
  '[DeliveryPositions] Load ChangedDeliveryPositions Success',
  props<{ key: any, data: any }>()
);

export const LoadRemovedDeliveryPositionsSuccess = createAction(
  '[DeliveryPositions] Load RemovedDeliveryPositions Success',
  props<{ key: any, data: any }>()
);

export const LoadDeliveryPositionsFailure = createAction(
  '[DeliveryPositions] Load DeliveryPositions Failure',
  props<{ error: any }>()
);
