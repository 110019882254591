import { Action } from '@ngrx/store';

export enum ActiveDeliveriesActionTypes {
  LoadActiveDeliveriess = '[ActiveDeliveries] Load ActiveDeliveriess',
  LoadActiveDeliveriessSuccess = '[ActiveDeliveries] Load ActiveDeliveriess Success',
  LoadActiveDeliveriessFailure = '[ActiveDeliveries] Load ActiveDeliveriess Failure',
}

export class LoadActiveDeliveriess implements Action {
  readonly type = ActiveDeliveriesActionTypes.LoadActiveDeliveriess;
}

export class LoadActiveDeliveriessSuccess implements Action {
  readonly type = ActiveDeliveriesActionTypes.LoadActiveDeliveriessSuccess;
  constructor(public payload: { data: any }) { }
}

export class LoadActiveDeliveriessFailure implements Action {
  readonly type = ActiveDeliveriesActionTypes.LoadActiveDeliveriessFailure;
  constructor(public payload: { error: any }) { }
}

export type ActiveDeliveriesActions = LoadActiveDeliveriess | LoadActiveDeliveriessSuccess | LoadActiveDeliveriessFailure;

