import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { GlobalInjectableService } from 'src/app/services/global-injectable.service';

@Component({
  selector: 'app-splash-screen',
  templateUrl: './splash-screen.component.html',
  styleUrls: ['./splash-screen.component.scss']
})
export class SplashScreenComponent implements OnInit {

  currentStaff: any;
  windowWidth: string = "";
  splashTransition: string = "";
  categories: any = [];
  regions: any = [];
  destroy$: Subject<boolean> = new Subject<boolean>()
  
  @Input() opacityChange: number = 1;
  //@Input() showSplash = true;
  @Output() showSplashEvent = new EventEmitter<boolean>();
  @Input() animationDuration: number = 1;
  @Input() duration: number = 3;

  isAuthenticated: boolean = false;
  isAuthenticatedHub: boolean = false;
  
  constructor(
    private globalInjectableService: GlobalInjectableService,
    
  ) { }

  ngOnInit(): void {
    //this.loadingData();
  }

  ngAfterViewInit(){
    this.globalInjectableService.currentIsSplashScreen.subscribe(
      (data: any) => {
        if(data){
          this.loadingData();
        }
      }
    )
  }

  

  async loadingData(){
    const controller = new AbortController();
    let isAgent: any; let isHub: any;
    
    /* let isGetEnvironment = new Promise(async (resolve) => {
     
    }) */

    isAgent = new Promise((resolve) => {
      if((location.hash.split('/')[1] == "hubs-monitor") || (location.hash.split('/')[1] == "hub") || (location.hash.split('/')[1] == "login")){
        this.globalInjectableService.currentIsAuthenticated.subscribe(
          (data: boolean) => {
            if(data != null){
              resolve(true)
            }
          },
          (err: any) => {
            resolve(false)
          }
        );
      }else{
        resolve(false)
      }
      controller.signal.addEventListener("abort", () => {
        resolve(false);
      });
    })

    isHub = new Promise((resolve) => {
      if(location.hash.split('/')[1] == "hub"){
        this.globalInjectableService.currentIsAuthenticatedHub.subscribe(
          (data: boolean) => {
            if(data != null){
              resolve(true)
            }
          },
          (err: any) => {
            resolve(false)
          }
        );
      }else{
        resolve(false)
      }
      controller.signal.addEventListener("abort", () => {
        resolve(false);
      });
    })

    Promise.all([isAgent, isHub]).then((values) => {
      this.undisplaySplashScreen()
      controller.abort();
    })

  }

  undisplaySplashScreen(){
    this.showSplashEvent.emit(false)
    this.globalInjectableService.changeIsSplashScreen(false);
  }

  ngOnDestroy() {
    this.undisplaySplashScreen()
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

}
