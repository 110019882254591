import { Injectable } from '@angular/core';
import { HttpserviceService } from './httpservice.service';

@Injectable({
  providedIn: 'root'
})
export class StaffService {

  constructor(private httpserviceService: HttpserviceService) { }

  createStaff(data: any){
    return this.httpserviceService.post(data, "staff");
  }
  
  updateStaffById(data: any, staff_id: number){
    return this.httpserviceService.post(data, "staff/" + staff_id);
  }
  
  updateDataById(data: any, staff_id: number){
    return this.httpserviceService.post(data, "staff/" + staff_id +"/update_data");
  }

  updateWorkingHours(data: any, staff_id: number){
    return this.httpserviceService.post(data, "staff_working_hours/" + staff_id);
  }

  filterStaff(params: any){
    return this.httpserviceService.get("staff?filters=" + params);
  }

  getWorkingHours(staff_id: number){
    return this.httpserviceService.get("staff_working_hours/" + staff_id);
  }
}
