import { Action } from '@ngrx/store';

export enum HubManagerActionTypes {
  LoadHubManagers = '[HubManager] Load HubManagers',
  LoadHubManagersSuccess = '[HubManager] Load HubManagers Success',
  LoadHubManagersFailure = '[HubManager] Load HubManagers Failure',
}

export class LoadHubManagers implements Action {
  readonly type = HubManagerActionTypes.LoadHubManagers;
}

export class LoadHubManagersSuccess implements Action {
  readonly type = HubManagerActionTypes.LoadHubManagersSuccess;
  constructor(public payload: { data: any }) { }
}

export class LoadHubManagersFailure implements Action {
  readonly type = HubManagerActionTypes.LoadHubManagersFailure;
  constructor(public payload: { error: any }) { }
}

export type HubManagerActions = LoadHubManagers | LoadHubManagersSuccess | LoadHubManagersFailure;

