import { Action, createReducer, on } from '@ngrx/store';
import * as DeliveryPositionsActions from './delivery-positions.actions'

export const deliveryPositionsFeatureKey = 'deliveryPositions';

export interface State {
  deliveryPosition: any;
  error: string;
}

export const initialState: State = {
  deliveryPosition: <any>{},
  error: ''
};

export const reducer = createReducer(
  initialState,

  on(DeliveryPositionsActions.LoadDeliveryPositions, (state) => ({
    ...state
  })),

  on(DeliveryPositionsActions.LoadDeliveryPositionsSuccess, (state, action) => ({
    ...state,
    deliveryPosition: action.data
  })),

  on(DeliveryPositionsActions.LoadAddedDeliveryPositionsSuccess, (state, action) => ({
    ...state,
    deliveryPosition: {
      ...state.deliveryPosition,
      [action.key]: action.data
    },
    error: '',
  })),

  on(DeliveryPositionsActions.LoadChangedDeliveryPositionsSuccess, (state, action) => ({
    ...state,
    deliveryPosition: {
      ...state.deliveryPosition,
      [action.key]: action.data
    },
    error: '',
  })),

  on(DeliveryPositionsActions.LoadRemovedDeliveryPositionsSuccess, (state, action) => {
    let deliveryPosition = { ...state.deliveryPosition };
    delete deliveryPosition[action.key];
    return ({
      ...state,
      deliveryPosition: deliveryPosition,
      error: '',
    })
  }),

  on(DeliveryPositionsActions.LoadDeliveryPositionsFailure, (state, action) => ({
    ...state,
    error: action.error
  })),
);
