<section class="container-fluid bg-green-fbeeper w-100">
    <div class="row px-2 py-2 py-md-0" style="min-height: 100vh;">
        <div class="col-12 col-sm-12 col-md-8 d-flex justify-content-center align-items-center">
            <img src="assets/images/Wajeez-logo-vertical-white.svg" alt="Wajeez" class="animated fadeIn w-50" style="max-height: 500px;">
        </div>
        <div class="col-12 col-sm-12 col-md-4 bg-white d-flex align-items-center animated fadeInRight">
            <form class="container w-100" [formGroup]="loginForm" (ngSubmit)="login(btnLogin)">
                <div class="form-group">
                    <h4 class="font-fbeeper color-green-fbeeper"><B> Welcome to</B></h4>
                    <h4 class="font-fbeeper text-dark"><B> Wajeez </B> HUBS MONITOR </h4>
                    <h4 class="font-fbeeper text-dark"> {{currentUserLocation?.country_name}} </h4>
                </div>
                <div class="form-group" style="margin-top: 4rem;">
                    <input type="username" class="form-control border-top-0 border-end-0 border-start-0" id="inputUsername" formControlName="username" placeholder="Nom d'utilisateur">
                    <small class="form-text text-danger" *ngIf="loginForm.controls.username.invalid && loginForm.controls.username.touched && loginForm.controls.username.errors['required']">Le nom d'utilisateur est obligatoire</small>
                </div>
                <div class="form-group mt-3">
                    <input type="password" class="form-control border-top-0 border-end-0 border-start-0" id="inputPassword" formControlName="password" placeholder="Mot de passe" autocomplete="off">
                    <small class="form-text text-danger" *ngIf="loginForm.controls.password.invalid && loginForm.controls.password.touched">Le mot de passe est obligatoire</small>
                </div>
                <button type="submit" #btnLogin class="btn btn-green-fbeeper w-100 d-flex justify-content-center align-items-center" style="margin-top: 4rem;"> 
                    <div *ngIf="btnLogin.disabled && loginForm.valid" 
                        class="spinner-border" 
                        style="height: 1rem !important; width: 1rem !important; margin-right: 5px;" 
                        role="status">
                    </div>
                    Connexion
                </button>
                
            </form>
        </div>
    </div>
</section>
<p-toast key="KeyLogin" [breakpoints]="{'920px': {width: '100%', right: '0', left: '0'}}"></p-toast>
