import { createFeatureSelector, createSelector } from '@ngrx/store';

import { State } from './online-visitors.reducer'

const getOnlineVisitorsFeatureState = createFeatureSelector<State>('onlineVisitors')

export const getCurrentHubOnlineVisitors = createSelector(
    getOnlineVisitorsFeatureState,
    (state: any) => state.current_hub_online_visitors
)

export const getCurrentError = createSelector(
    getOnlineVisitorsFeatureState,
    (state: any) => state.error
)