import { Action } from '@ngrx/store';

export enum OnlineVisitorsActionTypes {
  LoadCurrentHubOnlineVisitors = '[OnlineVisitors] Load CurrentHubOnlineVisitors',
  LoadCurrentHubOnlineVisitorsSuccess = '[OnlineVisitors] Load CurrentHubOnlineVisitors Success',
  LoadCurrentHubOnlineVisitorsFailure = '[OnlineVisitors] Load CurrentHubOnlineVisitors Failure',
}

export class LoadCurrentHubOnlineVisitors implements Action {
  readonly type = OnlineVisitorsActionTypes.LoadCurrentHubOnlineVisitors;
}

export class LoadCurrentHubOnlineVisitorsSuccess implements Action {
  readonly type = OnlineVisitorsActionTypes.LoadCurrentHubOnlineVisitorsSuccess;
  constructor(public payload: { data: any }) { }
}

export class LoadCurrentHubOnlineVisitorsFailure implements Action {
  readonly type = OnlineVisitorsActionTypes.LoadCurrentHubOnlineVisitorsFailure;
  constructor(public payload: { error: string }) { }
}

export type OnlineVisitorsActions = LoadCurrentHubOnlineVisitors | LoadCurrentHubOnlineVisitorsSuccess | LoadCurrentHubOnlineVisitorsFailure;

