import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import * as deliveryActions from './delivery.actions';
import { FirebaseService } from 'src/app/services/firebase.service';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { ImagesService } from 'src/app/services/images.service';
import { onChildAdded, onChildChanged, onChildRemoved } from "firebase/database";



@Injectable()
export class DeliveryEffects {

  constructor(
    private actions$: Actions,
    private firebaseService: FirebaseService,
    private imagesService: ImagesService
  ) { }

  loadDelivery$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(deliveryActions.DeliveryActionTypes.LoadDeliverys),
      mergeMap(
        () => {
          return new Observable<Action>((observer) => {
            onChildAdded(this.firebaseService.getStatusDelivery(), async (childSnapshot, prevChildKey) => {
              const deliveryKey = childSnapshot.key;
              const deliveryData = childSnapshot.val();
              await this.imagesService.fetchImage(deliveryData?.photo).then((data: any) => {
                deliveryData.cached_photo = data;
              });
              observer.next(new deliveryActions.LoadAddedDeliverysSuccess({ key: deliveryKey, data: deliveryData }));
            });
            onChildChanged(this.firebaseService.getStatusDelivery(), async (childSnapshot, prevChildKey) => {
              const deliveryKey = childSnapshot.key;
              const deliveryData = childSnapshot.val();
              await this.imagesService.getImage(deliveryData?.photo).then((data: any) => {
                deliveryData.cached_photo = data;
              });
              observer.next(new deliveryActions.LoadChangedDeliverysSuccess({ key: deliveryKey, data: deliveryData }));
            });
            onChildRemoved(this.firebaseService.getStatusDelivery(), async (childSnapshot) => {
              const deliveryKey = childSnapshot.key;
              const deliveryData = childSnapshot.val();
              observer.next(new deliveryActions.LoadRemovedDeliverysSuccess({ key: deliveryKey, data: deliveryData }));
            });
          }).pipe(
            catchError(err => of(new deliveryActions.LoadDeliverysFailure({ error: err })))
          );
        }
      )
    )
  )
}
