import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-fatal-error',
    templateUrl: './fatal-error.component.html',
    styleUrls: ['./fatal-error.component.scss']
})
export class FatalErrorComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
