import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { firstValueFrom, of, tap } from 'rxjs';

interface CachedImage {
  url: string;
  blob: Blob;
}

@Injectable({
  providedIn: 'root'
})
export class ImagesService {
  private _cachedImages: CachedImage[] = [];

  constructor(
    private http: HttpClient,
    private sanitization: DomSanitizer,
  ) { }

  async getImage(url: string) {
    const index = this._cachedImages.findIndex(image => image.url === url);
    if (index > -1) {
      const image = this._cachedImages[index];
      let unsafeImageUrl = URL.createObjectURL(image.blob);
      return this.sanitization.bypassSecurityTrustUrl(unsafeImageUrl);
    }
    return await this.fetchImage(url);
  }

  async fetchImage(url: string){
    return await firstValueFrom(this.http.get(url, { responseType: 'blob' })).then
      (blob => {
        this.checkAndCacheImage(url, blob)
        let unsafeImageUrl = URL.createObjectURL(blob);
        return this.sanitization.bypassSecurityTrustUrl(unsafeImageUrl);
      })
    .catch((err: any)=>{
      const index = this._cachedImages.findIndex(image => image.url.endsWith("assets/images/user.png"));
      if (index > -1) {
        const image = this._cachedImages[index];
        let unsafeImageUrl = URL.createObjectURL(image.blob);
        return this.sanitization.bypassSecurityTrustUrl(unsafeImageUrl);
      }
      return ("assets/images/user.png");
    });
  }

  checkAndCacheImage(url: string, blob: Blob) {
    const index = this._cachedImages.findIndex(image => image.url === url);
    if (index == -1) {
      this._cachedImages.push({url, blob});
    }
  }

  getCachedImage(url: string) {
    const index = this._cachedImages.findIndex(image => image.url === url);
    if (index > -1) {
      const image = this._cachedImages[index];
      let unsafeImageUrl = URL.createObjectURL(image.blob);
      return this.sanitization.bypassSecurityTrustUrl(unsafeImageUrl);
    }
    return undefined;
  }

  set cachedImages(image: CachedImage[]) {
    //this._cachedImages.push(image);
  }

  get cachedImages(): CachedImage[] {
    return this._cachedImages;
  }
}
