import { Action } from '@ngrx/store';
import { HubActions, HubActionTypes } from './hub.actions';


export const hubFeatureKey = 'hub';

export interface State {

}

export const initialState: State = {
  hub: <any>{},
  error: <any>''
};

export function reducer(state = initialState, action: HubActions): State {
  switch (action.type) {

    case HubActionTypes.LoadHubs:
      return {
        ...state
      }

    case HubActionTypes.LoadHubsSuccess:
      return {
        ...state,
        hub: action.payload.data,
        error: '',
      }

    case HubActionTypes.LoadHubsFailure:
      return {
        ...state,
        hub: <any>{},
        error: action.payload.error,
      }

    default:
      return state;
  }
}
