import { Component, OnInit } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { MessageService } from 'primeng/api';
import { Subject, Observable} from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as fromUser from 'src/app/ngrx/user/user.selectors'
import { AuthService } from '../authentication/auth.service';
import { FirebaseService } from '../services/firebase.service';
import { GlobalInjectableService } from '../services/global-injectable.service';
import { OtherService } from '../services/other.service';
import { getCurrentUserLocation } from 'src/app/ngrx/user-location/user-location.selectors'
import * as fromActiveErrands from 'src/app/ngrx/active-errands/active-errands.selectors'

@Component({
  selector: 'app-hubs-monitor',
  templateUrl: './hubs-monitor.component.html',
  styleUrls: ['./hubs-monitor.component.scss']
})
export class HubsMonitorComponent implements OnInit {

  complete: boolean = false;
  confirmationModal: boolean = false;
  confirmationHubLogoutModal: boolean = false;
  hubLoginModal: boolean = false;
  destroy$: Subject<boolean> = new Subject<boolean>()
  currentAgent: any;
  hubsList: any = [];
  SMSSendStatus: any = [];
  SMSSendReceivedSMS: any = {};
  currentUserLocation: any = <any>{ }
  currentActiveErrands: any = {}

  selectedHub: any;
  loading: boolean = false;
  loader: boolean = false;
  loader_sms: boolean = false;
  loader_hubs: number = 100;

  constructor(
    private router: Router,
    private titleService: Title,
    private store: Store,
    private authService : AuthService,
    private messageService: MessageService,
    private firebaseService: FirebaseService,
    public otherService: OtherService,
    private firebaseDB: AngularFireDatabase,
    private globalInjectableService: GlobalInjectableService,
  ) { }

  hubLoginForm: any;
  initHubLoginForm(){
    this.hubLoginForm = new FormGroup({
      username: new FormControl('', [Validators.required, Validators.minLength(1)]),
      password: new FormControl('', [Validators.required, Validators.minLength(1)]),
    })
  }

  ngOnInit(): void {
    console.log("in hubs monitor")
    this.titleService.setTitle("Hubs monitor");
    this.store.pipe(select(fromUser.getCurrentUser), takeUntil(this.destroy$)).subscribe(
      (user: any) =>{
        this.currentAgent = user;
      }
    )
    this.initHubLoginForm();
    this.getLoggedHubs();
    this.subscribeToSMSSend();
    //this.checkPendingOrdersTime()
    setInterval(() => this.checkPendingOrdersTime(), 10000);

    this.store.pipe(select(getCurrentUserLocation), takeUntil(this.destroy$)).subscribe(
      (data: any) => {
        this.currentUserLocation = data;
      }
    )

    this.store.pipe(select(fromActiveErrands.getCurrentActiveErrands), takeUntil(this.destroy$)).subscribe(
      (data: any) => {
        this.currentActiveErrands = data;
      }
    )
  }

  hubLogin(btn: any){
    btn.disabled = true;
    if (this.hubLoginForm.valid) {
      this.authService.login(this.hubLoginForm.value).pipe(takeUntil(this.destroy$)).subscribe(
        (result: any)=>{
          this.authService.getUserByToken(result.token).subscribe(
            (res: any) => {
              if(res?.data?.profile?.type == "hub_manager"){
                if(Object.keys(localStorage).indexOf("token_hub_" + res.data.profile.hub_id) != -1){
                  this.hubsList = this.hubsList.filter((element: any) => element.profile.hub_id != res.data.profile.hub_id)
                }
                localStorage.setItem("token_hub_" + res.data.profile.hub_id, result.token);
                this.hubsList.push(<any>res?.data);
                this.hubsList = [... this.hubsList ]
                this.subscribeToFirebase(res?.data);
  
                this.complete = true;
                //setTimeout(() => {
                  btn.disabled = false; this.complete = false; this.hubLoginModal = false;
                //}, 1000);
              }else{
                this.messageService.clear();
                this.messageService.add({key: 'KeyLogin', severity:'error', summary:'Erreur', detail: "Cet utilisateur n'est pas un hub manager"});
                btn.disabled = false; this.complete = false;
              }
            },
            (err: any) => {
              this.messageService.clear();
              this.messageService.add({key: 'KeyLogin', severity:'error', summary:'Erreur', detail: JSON.stringify(err)});
              btn.disabled = false; this.complete = false;
            }
          );
        },
        (err: any)=>{
          this.messageService.clear();
          this.messageService.add({key: 'KeyLogin', severity:'error', summary:'Erreur', detail: JSON.stringify(err)});
          btn.disabled = false; this.complete = false;
        }
      );
    }else {
      Object.keys(this.hubLoginForm.controls).forEach(field => {
      const control = this.hubLoginForm.get(field);
      control.markAsTouched({ onlySelf: true });
     });
     btn.disabled = false; this.complete = false;
    }
  }

  getLoggedHubs(){
    this.hubsList = [];
    let hubsCount = Object.keys(localStorage).filter(key => key.startsWith("token_hub_"))?.length
    Object.keys(localStorage).forEach(key => {
      if(key.startsWith("token_hub_")){
        this.loader_hubs = 0
        this.authService.getUserByToken(localStorage.getItem(key) || "").pipe(takeUntil(this.destroy$)).subscribe(
          (res: any) => {
            this.hubsList.push(<any>res?.data);
            this.loader_hubs = Math.floor((this.hubsList.length * 100) / hubsCount)
            this.subscribeToFirebase(res?.data);
          },
          (err: any) => {
            this.messageService.clear();
            this.messageService.add({key: 'hubsMonitor', severity:'error', summary:'Erreur', detail: JSON.stringify(err)});
            //this.loader_hubs = Math.floor((this.loader_hubs++ * hubsCount) / 100)
            if(hubsCount > 1){
              hubsCount = hubsCount - 1;
              this.loader_hubs = Math.floor((this.hubsList.length * 100) / hubsCount)
            }else{
              this.loader_hubs = 100
            }
          }
        );
      }
    });
  }

  isFirebaseConnected: any;
  subscribeToFirebase(hub_data: any){
    let isStatus: any; let isActiveDeliveries: any; let isOnlineStoresByRegion: any; let isFirebaseConnected: any; let isActiveErrands: any;
    this.loader = true; 

    isStatus = new Promise((resolve) => {
      this.firebaseService.getStatus(hub_data?.profile?.hub?.region?.id, hub_data?.profile?.hub?.id).valueChanges().pipe(takeUntil(this.destroy$)).subscribe(
        (data: any) => {
          const index = this.hubsList.findIndex((element: any) => element?.profile?.hub?.id == hub_data?.profile?.hub?.id )
          if(index !== -1){
            this.hubsList[index].status = data || [];
            this.hubsList[index].status.zones = this.countZones(this.hubsList[index]?.status?.hub_manager?.delivery_zones) || [];
          }
          resolve(true);
        }
      );
    });

    isActiveDeliveries = new Promise((resolve) => {
      this.firebaseService.getActiveDeliveries(hub_data?.profile?.hub?.region?.id, hub_data?.profile?.hub?.id).valueChanges().pipe(takeUntil(this.destroy$)).subscribe(
        (data: any) => {
          const index = this.hubsList.findIndex((element: any) => element?.profile?.hub?.id == hub_data?.profile?.hub?.id )
          if(index !== -1){
            this.hubsList[index].active_deliveries = data || [];
            //this.hubsList[index].active_deliveries_accepted = Object.keys(this.hubsList[index]?.active_deliveries || []).filter(key => key != "pending") || [];
            this.hubsList[index].active_deliveries_pending = Object.keys(this.hubsList[index]?.active_deliveries?.pending || [])?.length || 0;
            //this.hubsList[index].connected_staff_delivery = Object.entries(this.hubsList[index]?.status?.delivery || []).filter(([key, value]: any) => value?.online != 0) || [];

            let active_deliveries_accepted: any = 0;
            Object.keys(this.hubsList[index]?.active_deliveries || []).forEach((key: any) => {
              if(key != "pending"){
                active_deliveries_accepted += Object.keys(this.hubsList[index]?.active_deliveries[key] || []).length
              }
            })
            this.hubsList[index].active_deliveries_accepted = active_deliveries_accepted
          }
          resolve(true);
        }
      );
    });

    isActiveErrands = new Promise((resolve) => {
      this.firebaseService.getActiveErrands(hub_data?.profile?.hub?.region?.id, hub_data?.profile?.hub?.id).valueChanges().pipe(takeUntil(this.destroy$)).subscribe(
        (data: any) => {
          const index = this.hubsList.findIndex((element: any) => element?.profile?.hub?.id == hub_data?.profile?.hub?.id )
          if(index !== -1){
            this.hubsList[index].active_errands = data || [];
            this.hubsList[index].active_errands_pending = Object.keys(this.hubsList[index]?.active_errands?.pending || [])?.length || 0;
            
            let active_errands_accepted: any = 0;
            Object.keys(this.hubsList[index]?.active_errands || []).forEach((key: any) => {
              if(key != "pending"){
                active_errands_accepted += Object.keys(this.hubsList[index]?.active_errands[key] || []).length
              }
            })

            this.hubsList[index].active_errands_accepted = active_errands_accepted;
          }
          resolve(true);
        }
      );
    });

    isOnlineStoresByRegion = new Promise((resolve) => {
      this.firebaseService.getOnlineStoresByRegion(hub_data?.profile?.hub?.region?.id).valueChanges().pipe(takeUntil(this.destroy$)).subscribe(
        (data: any) => {
          const index = this.hubsList.findIndex((element: any) => element?.profile?.hub?.id == hub_data?.profile?.hub?.id )
          if(index !== -1){
            this.hubsList[index].stores_online = Object.entries(data || []).filter(([key, value]: any) => Object.keys(this.hubsList[index]?.status?.stores || []).indexOf(key) != -1) || [];
          }
          resolve(true);
        }
      );
    });

    isFirebaseConnected = new Promise((resolve) => {
      this.globalInjectableService.currentIsFirebaseConnected.pipe(takeUntil(this.destroy$)).subscribe(
        (data: any) => {
          this.isFirebaseConnected = data;
          if(data){
            resolve(true);
          }
        }
      );
    });

    Promise.all([isStatus, isActiveDeliveries, isOnlineStoresByRegion]).then((values) => {
      if(values[0] && values[1] && values[2]){
        this.loader = false;
        this.checkPendingOrdersTime()
      }
    })
  }

  checkPendingOrdersTime(){
    this.hubsList.forEach((hub: any) => {
      hub.active_deliveries_urgent = false
      Object.values(hub?.active_deliveries?.pending || [])
            .forEach((element: any) => {
              const today: Date = new Date();
              const created_date: Date = new Date(element?.created?.date);
              const deliver_at: Date = new Date(element?.order?.deliver_at?.date);
              const accepted_at: Date = new Date(element?.order?.accepted_at?.date);
              const timeDifferenceInMinutes_created_date = (today.getTime() - created_date.getTime()) / (1000 * 60);
              const timeDifferenceInMinutes_deliver_at = (today.getTime() - deliver_at.getTime()) / (1000 * 60);
              const timeDifferenceInMinutes_accepted_at = (today.getTime() - accepted_at.getTime()) / (1000 * 60);

              if((element?.status == "pending" 
                || element?.status == "new")
                && timeDifferenceInMinutes_created_date > 10){
                  hub.active_deliveries_urgent = true
              }
              if(element?.status == "accepted" 
                && timeDifferenceInMinutes_deliver_at > 10){
                  hub.active_deliveries_urgent = true
              }
              if(element?.status == "accepted" 
                && timeDifferenceInMinutes_accepted_at > 10){
                  hub.active_deliveries_urgent = true
              }
            });
      /* hub.active_deliveries_urgent = new Observable<boolean>((observer: any) => {
        setInterval(() => observer.next(hub.active_deliveries_urgent), 60000);
      }); */
      
    })
  }
  
  subscribeToSMSSend(){
    let isSMSSend: any; let isReceivedSMS: any;
    //this.loader_sms = true;

    isSMSSend = new Promise((resolve) => {
      this.firebaseService.getSMSSendStatus().valueChanges().pipe(takeUntil(this.destroy$)).subscribe(
        (data: any) => {
          if(data){
            this.SMSSendStatus = data
            resolve(true);
          }
        }
      );
    });

    isReceivedSMS = new Promise((resolve) => {
      this.firebaseService.getSMSSendReceivedSMS().valueChanges().pipe(takeUntil(this.destroy$)).subscribe(
        (data: any) => {
          if(data){
            this.SMSSendReceivedSMS = data;
            resolve(true);
          }
        }
      );
    });

    Promise.all([isSMSSend, isReceivedSMS]).then((values) => {
      if(values[0] && values[1]){
        this.loader_sms = false;
      }
    })
  }

  removeUnknownSMS(key: any){
    this.firebaseDB.list("/sms_send/received_sms/"+key).remove();
  }

  countZones(delivery_zones: any){
    let active_Zones_count: any = []; let all_zones_count: any = [];

    if(delivery_zones){
      active_Zones_count = Object.keys(delivery_zones).filter((zone: any) => (delivery_zones[zone]?.can_deliver != 0 && delivery_zones[zone]?.available != 0)).length
      all_zones_count =  Object.keys(delivery_zones).length
    }

    return {
      all_zones_count: all_zones_count,
      active_Zones_count: active_Zones_count
    } 
  }

  onRowClick(hub: any, type: number){
    switch (type) {
      case 1:
        //if (!this.currentAgent?.profile?.permissions?.fleet_manager)
          window.open('/#/hub/'+ hub?.profile?.hub?.id + '/dashboard', '_blank');
        break;

      case 2:
        window.open('/#/hub/'+ hub?.profile?.hub?.id + '/staff?order_by=-money', '_blank');
        break;

      case 3:
        window.open('/#/hub/'+ hub?.profile?.hub?.id + '/staff?order_by=-money', '_blank');
        break;

      case 4:
        window.open('/#/hub/'+ hub?.profile?.hub?.id + '/zones', '_blank');
        break;

      case 5:
        window.open('/#/hub/'+ hub?.profile?.hub?.id + '/staff?order_by=-working;available', '_blank');
        break;

      case 6:
        window.open('/#/hub/'+ hub?.profile?.hub?.id + '/clients-tickets', '_blank');
        break;

      case 7:
        window.open('/#/hub/'+ hub?.profile?.hub?.id + '/staff?order_by=-avg_delivery_time', '_blank');
        break;

      case 8:
        window.open('/#/hub/'+ hub?.profile?.hub?.id + '/staff?order_by=-performance', '_blank');
        break;

      case 9:
        window.open('/#/hub/'+ hub?.profile?.hub?.id + '/stores', '_blank');
        break;
    
      default:
        
        break;
    }
  }

  hubLogout(hub: any){
    if(Object.keys(localStorage).indexOf("token_hub_" + hub.profile.hub?.id) != -1){
      this.hubsList = this.hubsList.filter((element: any) => element?.profile?.hub?.id != hub?.profile?.hub?.id)
    }
    localStorage.removeItem("token_hub_" + hub?.profile?.hub?.id);
    this.confirmationHubLogoutModal = false;
  }

  logout(){
    localStorage.removeItem("token_auth_agent");
    localStorage.removeItem("sub_token_hub_agent");
    this.confirmationModal = false;
    this.router.navigate(["/login"]);
  }

  ngOnDestroy() {
    this.titleService.setTitle("Wajeez hub");
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
