import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { FirebaseService } from 'src/app/services/firebase.service';
import * as ActiveErrandsActions from './active-errands.actions'
import { Observable, catchError, mergeMap, map, of } from 'rxjs';


@Injectable()
export class AtiveErrandsEffects {


  constructor(
    private actions$: Actions,
    private firebaseService: FirebaseService,
  ) {}

  loadActiveErrand$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ActiveErrandsActions.loadActiveErrandss),
      mergeMap(async () =>{
        let getActiveErrands = <any>{}
        getActiveErrands = await this.firebaseService.getActiveErrands().pipe(
          map(activeErrands => {
            let setting: any = {... activeErrands}
            ActiveErrandsActions.loadActiveErrandssSuccess({ data: setting })
          }),
          catchError(err => of(ActiveErrandsActions.loadActiveErrandssFailure({error: err})))
        )
        return getActiveErrands;
      })
    )
  )
}
