import { KeyValue } from '@angular/common';
import { Injectable } from '@angular/core';
import { FormArray, FormControl } from '@angular/forms';
import { Store, select } from '@ngrx/store';
import { getCurrentDeliveryPositions } from '../ngrx/status/delivery-positions/delivery-positions.selectors';
import * as DeliveryPositionsActions from 'src/app/ngrx/status/delivery-positions/delivery-positions.actions'
import { firstValueFrom, map } from 'rxjs';
declare var google: any;
@Injectable({
  providedIn: 'root'
})
export class OtherService {

  constructor(private store: Store) { }

  milliSecondsInASecond = 1000;
  hoursInADay = 24;
  minutesInAnHour = 60;
  SecondsInAMinute  = 60;

  public getTimeDifference (dDay: Date, type: string = "countdown") {
    dDay = new Date(dDay)
    let timeDifference = dDay.getTime() - new Date().getTime();
    if(type === "timer"){
      return this.timer(timeDifference);
    }else{
      return this.allocateTimeUnits(timeDifference);
    }
  }

  public allocateTimeUnits (timeDifference: number) {
    let secondsToDday = Math.trunc((timeDifference) / (this.milliSecondsInASecond) % this.SecondsInAMinute);
    let minutesToDday = Math.trunc((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour) % this.SecondsInAMinute);
    let hoursToDday = Math.trunc((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour * this.SecondsInAMinute) % this.hoursInADay);
    let daysToDday = Math.trunc((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour * this.SecondsInAMinute * this.hoursInADay));
    return {
      secondsToDday: secondsToDday,
      minutesToDday: minutesToDday,
      hoursToDday: hoursToDday,
      daysToDday: daysToDday,
      /* months: months,
      years: years */
    }
  }

  private timer (timeDifference: any) {
    let secondsToDday = Math.trunc((timeDifference) / (this.milliSecondsInASecond) % this.SecondsInAMinute);
    let minutesToDday = Math.trunc((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour) % this.SecondsInAMinute);
    let hoursToDday = Math.trunc((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour * this.SecondsInAMinute));

    let dispay = ""

    if(hoursToDday){
      dispay += Math.abs(hoursToDday).toString().padStart(2, '0') + ":"
    }
    dispay += Math.abs(minutesToDday).toString().padStart(2, '0') + ":"
    dispay += Math.abs(secondsToDday).toString().padStart(2, '0')

    return dispay;
  }

  public avgDeliveryTime(time: number){
    let timeC = this.allocateTimeUnits(time);
    let dispay = ""

    if(timeC.daysToDday){
      dispay += timeC.daysToDday + "d "
    }
    if(timeC.hoursToDday){
      dispay += timeC.hoursToDday + "h "
    }
    if(timeC.minutesToDday){
      dispay += timeC.minutesToDday + "m"
    }
    if(timeC.secondsToDday){
      dispay += timeC.secondsToDday + "s"
    }

    return dispay
  }

  countZones(delivery_zones: any){
    let active_Zones_count: any = []; let all_zones_count: any = [];

    if(delivery_zones){
      active_Zones_count = Object.keys(delivery_zones).filter((zone: any) => (delivery_zones[zone]?.can_deliver != 0 && delivery_zones[zone]?.available != 0)).length
      all_zones_count =  Object.keys(delivery_zones).length
    }

    return {
      all_zones_count: all_zones_count,
      active_Zones_count: active_Zones_count
    } 
  }

  dateFormat(date: Date){
    if(date){
      return (date?.toISOString().split('T')[0] + " " + date?.toTimeString().slice(0,8))
    }else{
      return null
    }
  }

  public onCompareReverse(_left: KeyValue<any, any>, _right: KeyValue<any, any>): number {
    return -1;
  }

  public onCompare(_left: KeyValue<any, any>, _right: KeyValue<any, any>): number {
    return 1;
  }

  async globalFilterWorkingDelivery(all_delivery: boolean, delivery: any, delivery_positions: any, order: any = null){
    /* var delivery_positions = {}
    await firstValueFrom(this.store.pipe(select(getCurrentDeliveryPositions)).pipe(map(
      (res: any) => {
        console.log(res)
        delivery_positions = res;
      }
    ))).catch((err: any)=>{
      this.store.dispatch(DeliveryPositionsActions.LoadDeliveryPositions());
    }); */
    let workingDelivery = Object.entries(delivery || []).map(([key, value]: [any, any]) => {
      value.staff_id = +key;
      if(delivery_positions[key]){
        value.position = delivery_positions[key]
      }
      return value;
    })

    if(!all_delivery){
      workingDelivery = Array.isArray(workingDelivery) ? workingDelivery?.filter((value: any) => (value?.working == 1) 
                                                                                                  && (value?.staff_id !== order?.staff_id)) 
                                                                                                  : [];
      let today = new Date().getTime();
      if(order){
        workingDelivery = workingDelivery.map((element: any) => {
          let position_date = new Date(element?.position?.created?.date || "").getTime()
          if(today - position_date < 300000){
            let storeLocation = this.getPosition(order?.order?.store?.location);
            let staffLocation = this.getPosition(element?.position?.location);
            element.distance = this.getDistanceFromLatLngInKm(staffLocation.lat, staffLocation.lng, storeLocation.lat, storeLocation.lng).toFixed(1) || null;
          }else{
            element.distance = null;
          }
          return element;
        })
      }
    }
    
    //workingDelivery.sort((a: any, b: any) => (+a?.distance - +b?.distance)? 0 : (+a?.online - +b?.online)? 0 : (+a?.working)? 0 : -1)
    //workingDelivery.sort((a: any, b: any) => (b?.distance - a?.distance) || (b?.online - a?.online) || (a?.working - b?.working))

    workingDelivery.sort(
      (a: any, b: any) => {
        let temp = 0

        if(a?.distance === null && b?.distance !== null){
          temp =  0;
        }else if(a?.distance !== null && b?.distance === null){
          temp =  -1;
        }else if(a?.distance === null && b?.distance === null){
          temp =  b?.online - a?.online;
        }else if(a?.distance !== null && b?.distance !== null){
          temp =  +a?.distance - +b?.distance
        }

        /* if (temp === 0) {
          temp = b?.online - a?.online;
        } */
        
        return temp
      }
    )
    //console.log(workingDelivery)
    return workingDelivery;
  }
  

  //with check all option
  onCheckboxFormArrayChange(e: any, form: any, field: any, data: any = null) {
    const element: FormArray = form.get(field) as FormArray;

    if(e.target.value === ""){
      while (element.length !== 0) {
        element.removeAt(0)
      }
      if (e.target.checked) {
        data.map((ele: any) => {
          element.push(new FormControl(+ele.id));
        });
      }
    }else{
      if (e.target.checked) {
        element.push(new FormControl(+e.target.value));
      } else {
        const index = element.controls.findIndex(x => x.value == e.target.value);
        if(index !== -1){
          element.removeAt(index);
        }
      }
    }
    return element;
  }

  getPosition(position: any) {
    let pos;
    let start = position?.indexOf("(");
    let end = position?.indexOf(")");
    start++;
    pos = position?.slice(start, end);
    pos = (pos?.split(/\s+/) || []);
    return {
      lat: parseFloat(pos[0]), lng: parseFloat(pos[1])
    }
  }

  parsePath(polyPaths: any) {
    const paths: any = []
    let cordsText:string =  polyPaths.substring(9, polyPaths.length-2)
    let cords = cordsText.split(',')
    cords.forEach(latLng => {
      let point = latLng.split(' ')
      paths.push({lat: Number(point[0]), lng: Number(point[1])})
    });
    return paths;
  }

  randomColor(){
    var r = () => Math.random() * 256 >> 0;
    return `rgb(${r()}, ${r()}, ${r()}, 1)`;
  }

  generateColor(str: any){
    let hash = 0;
    for(let i=0; i < str.length; i++){
      hash += str.charCodeAt(i);
    }
    hash = Math.pow((hash % 799 + 97)*13,3);
    const r = hash % 90 + 110;
    hash = Math.floor(hash/100);
    const g = hash % 90 + 110;
    hash = Math.floor(hash/100);
    const b = hash % 90 + 110;
    return "rgb("+r+','+g+','+b+')';
  }

  onCheckboxChange(e: any, form: any, field: any) {
    const element: FormArray = form.get(field?.toString()) as FormArray;
    if (e.target.checked) {
      element.push(new FormControl(+e.target.value));
    } else {
       const index = element.controls.findIndex(x => +x.value === +e.target.value);
       if(index !== -1){
        element.removeAt(index);
      }
    }
  }

  drawZone(zone: any){
    let path = this.parsePath(zone?.zone);
    let color = this.generateColor(zone?.name);
    return new google.maps.Polygon({
      id: zone?.id,
      title:"zone", 
      path: path, 
      fillColor: color, 
      fillOpacity: 0.5,
      strokeColor: color, 
      strokeOpacity: 1, 
      strokeWeight: 2
    })
  }

  getDistanceFromLatLngInKm(lat1: any, lon1: any, lat2: any, lon2: any) {
    const R = 6371; // Radius of the earth in km
    const dLat = this.deg2rad(lat2 - lat1);  // deg2rad below
    const dLon = this.deg2rad(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2)
      ;
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c; // Distance in km
    return d;
  }

  deg2rad(deg: any) {
    return deg * (Math.PI / 180)
  }

  settingPauseStoreData(form: any){
    let data = <any>{};
    let date = new Date()
    if(form?.value.accept_delivery_at == "autre"){
      switch (form?.value.accept_delivery_on) {
        case "2jrs":
          date.setDate(date.getDate() + 2);
          date.setHours(1, 0, 0, 0);
          break;
        case "3jrs":
          date.setDate(date.getDate() + 3);
          date.setHours(1, 0, 0, 0);
          break;
        case "4jrs":
          date.setDate(date.getDate() + 4);
          date.setHours(1, 0, 0, 0);
          break;
        case "5jrs":
          date.setDate(date.getDate() + 5);
          date.setHours(1, 0, 0, 0);
          break;
        case "6jrs":
          date.setDate(date.getDate() + 6);
          date.setHours(1, 0, 0, 0);
          break;
        case "7jrs":
          date.setDate(date.getDate() + 7);
          date.setHours(1, 0, 0, 0);
          break;
      }
    }else{
      switch (form?.value.accept_delivery_at) {
        case "10m":
          date.setMinutes(date.getMinutes() + 10);
          break;
        case "30m":
          date.setMinutes(date.getMinutes() + 30);
          break;
        case "1h":
          date.setHours(date.getHours() + 1);
          break;
        case "3h":
          date.setHours(date.getHours() + 3);
          break;
        case "1d":
          date.setDate(date.getDate() + 1);
          date.setHours(1, 0, 0, 0);
          break;
        case 'today':
          date = form?.value?.accept_delivery_at_time
          break;
      }
    }
    data = <any>{
      accept_delivery: 0,
      accept_delivery_at: date.toISOString().split('T')[0] + " " + date.getHours().toString().padStart(2, '0') +":"+ date.getMinutes().toString().padStart(2, '0') +":"+ date.getSeconds().toString().padStart(2, '0')
    }

    return data;
  }
}

