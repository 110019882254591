import { createFeatureSelector, createSelector } from '@ngrx/store';

import { State } from './active-errands.reducer'

export const selectFeature = createFeatureSelector<State>('activeErrands')

export const getCurrentActiveErrands = createSelector(
    selectFeature,
    (state: any) => state.activeErrands
);

export const getCurrentError = createSelector(
    selectFeature,
    (state: any) => state.error
);