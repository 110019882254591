import { createAction, props } from '@ngrx/store';

export const loadActiveErrandss = createAction(
  '[ActiveErrands] Load ActiveErrandss'
);

export const loadActiveErrandssSuccess = createAction(
  '[ActiveErrands] Load ActiveErrandss Success',
  props<{ data: any }>()
);

export const loadActiveErrandssFailure = createAction(
  '[ActiveErrands] Load ActiveErrandss Failure',
  props<{ error: any }>()
);
