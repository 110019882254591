import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';
import * as UserLocationActions from 'src/app/ngrx/user-location/user-location.actions'
import { catchError, takeUntil, map } from 'rxjs/operators';
import { MessageService } from 'primeng/api';
import { MultinationalService } from '../services/multinational.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthMultinationalService {

  country_float_format = ['DZ'];
  country_int_format = ['SN'];

  constructor(
    private multinationalService: MultinationalService,
    private messageService: MessageService,
    private store: Store,
  ) { }

  changeEnvironment(getEnvironment: any){
    if(getEnvironment?.api_url){
      environment.apiUrl = getEnvironment?.api_url + "/api/"
    }
    if(getEnvironment?.db_url){
      localStorage.setItem("country_db_url_foodbeeper_hub", getEnvironment?.db_url);
      environment.firebaseConfig.databaseURL = getEnvironment?.db_url
    }
    //console.log("loadUserLocationsSuccess")
    getEnvironment.lang = (getEnvironment?.langs || []).filter((element: any) => element?.code == "fr")[0]
    if(this.country_float_format.includes(getEnvironment?.country_code)){
      getEnvironment.lang.format = '1.2-2';
    }else{
      getEnvironment.lang.format = '1.0-2';
    }
    this.store.dispatch(UserLocationActions.loadUserLocationsSuccess({ data: getEnvironment }))
  }

  async getCurrentCountry(){
    let country
    try{
      country = localStorage.getItem("country_foodbeeper_hub");
    }catch(error){
      this.messageService.clear();
      this.messageService.add({key: 'KeyHome', severity:'error', summary: 'Erreur', detail: JSON.stringify(error)});
    }

    if(country){
      await this.multinationalService.getCountryInfo(country)
            .pipe(map(
              (res: any) => {
                this.changeEnvironment(res);
              }
            ))
            .toPromise()
            .catch((err: any)=>{
              this.store.dispatch(UserLocationActions.loadUserLocationsFailure({ error: err }));
              this.messageService.clear();
              this.messageService.add({key: 'KeyHome', severity:'error', summary: 'Erreur', detail: JSON.stringify(err)});
            });
    }else{
      await this.multinationalService.getCountry()
            .pipe(map(
              (res: any) => {
                this.changeEnvironment(res);
              }
            ))
            .toPromise()
            .catch((err: any)=>{
              this.store.dispatch(UserLocationActions.loadUserLocationsFailure({ error: err }));
              this.messageService.clear();
              this.messageService.add({key: 'KeyHome', severity:'error', summary: 'Erreur', detail: JSON.stringify(err)});
            });
    }
  }
}
