import { Action } from '@ngrx/store';
import { OnlineActions, OnlineActionTypes } from './online.actions';


export const onlineFeatureKey = 'online';

export interface State {

}

export const initialState: State = {
  online: <any>{},
  error: <any>''
};

export function reducer(state = initialState, action: OnlineActions): State {
  switch (action.type) {

    case OnlineActionTypes.LoadOnlines:
      return {
        ...state
      }

    case OnlineActionTypes.LoadOnlinesSuccess:
      return {
        ...state,
        online: action.payload.data,
        error: '',
      }

    case OnlineActionTypes.LoadOnlinesFailure:
      return {
        ...state,
        online: <any>{},
        error: action.payload.error,
      }

    default:
      return state;
  }
}
