import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthHubGuard } from './authentication/auth-hub.guard';
import { AuthGuard } from './authentication/auth.guard';
import { NoAuthGuard } from './authentication/no-auth.guard';
import { CountriesComponent } from './countries/countries.component';
import { ErrorOccurredComponent } from './error-occurred/error-occurred.component';
import { FatalErrorComponent } from './fatal-error/fatal-error.component';
import { HubComponent } from './hub/hub.component';
import { HubsMonitorComponent } from './hubs-monitor/hubs-monitor.component';
import { LoginComponent } from './login/login.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ClientsTicketsComponent } from './clients-tickets/clients-tickets.component';
import { PermissionsGuard } from './authentication/permissions.guard';

const routes: Routes = [
  { path: "login", canActivate:[NoAuthGuard], component: LoginComponent},
  { path: "hubs-monitor", canActivate:[AuthGuard], component: HubsMonitorComponent},

  {
    path: 'hub/:hub_id',
    component: HubComponent,
    canActivate:[AuthGuard, AuthHubGuard],
    //canActivateChild: [RoleGuard],
    children:[
      //{ path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      { path: 'dashboard', 
        canActivate:[PermissionsGuard],
        data: { expectedPermission: ['customer_service', 'fleet_manager']},
        loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      { path: 'zones', loadChildren: () => import('./zones/zones.module').then(m => m.ZonesModule)},
      { path: 'staff', loadChildren: () => import('./staff/staff.module').then(m => m.StaffModule)},
      { path: 'transactions', loadChildren: () => import('./transactions/transactions.module').then(m => m.TransactionsModule)},
      { path: 'orders', loadChildren: () => import('./orders/orders.module').then(m => m.OrdersModule)},
      { path: "clients-tickets", loadChildren: () => import('./clients-tickets/clients-tickets.module').then(m => m.ClientsTicketsModule) },
      { path: "stores", loadChildren: () => import('./stores/stores.module').then(m => m.StoresModule) },
    ]
  },

  { path: '', redirectTo: '/hubs-monitor', pathMatch: 'full' },
  { path: "countries", component: CountriesComponent },
  { path: "oops", component: ErrorOccurredComponent },
  { path: "fatal", component: FatalErrorComponent },
  { path: '**', component: PageNotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, anchorScrolling: 'enabled', scrollPositionRestoration: 'enabled', onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
