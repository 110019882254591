import { Action } from '@ngrx/store';

export enum HubActionTypes {
  LoadHubs = '[Hub] Load Hubs',
  LoadHubsSuccess = '[Hub] Load Hubs Success',
  LoadHubsFailure = '[Hub] Load Hubs Failure',
}

export class LoadHubs implements Action {
  readonly type = HubActionTypes.LoadHubs;
}

export class LoadHubsSuccess implements Action {
  readonly type = HubActionTypes.LoadHubsSuccess;
  constructor(public payload: { data: any }) { }
}

export class LoadHubsFailure implements Action {
  readonly type = HubActionTypes.LoadHubsFailure;
  constructor(public payload: { error: any }) { }
}

export type HubActions = LoadHubs | LoadHubsSuccess | LoadHubsFailure;

