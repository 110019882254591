import {
  ActionReducer,
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
  MetaReducer
} from '@ngrx/store';
import { environment } from '../../environments/environment';
import * as fromUser from '../ngrx/user/user.reducer';
import * as fromOnlineVisitors from '../ngrx/online-visitors/online-visitors.reducer';
import * as fromHub from '../ngrx/hub/hub.reducer';
import * as fromStatus from '../ngrx/status/status.reducer';
import * as fromActiveDeliveries from '../ngrx/active-deliveries/active-deliveries.reducer';
import * as fromOnline from '../ngrx/online/online.reducer';
import * as fromDelivery from '../ngrx/status/delivery/delivery.reducer';
import * as fromHubManager from '../ngrx/status/hub-manager/hub-manager.reducer';
import * as fromStores from '../ngrx/status/stores/stores.reducer';
import * as fromUserLocation from '../ngrx/user-location/user-location.reducer';
import * as fromActiveErrands from '../ngrx/active-errands/active-errands.reducer';
import * as fromStaff from '../ngrx/staff/staff.reducer';
import * as fromDeliveryPositions from '../ngrx/status/delivery-positions/delivery-positions.reducer';


export interface State {

  [fromUser.userFeatureKey]: fromUser.State;
  [fromOnlineVisitors.onlineVisitorsFeatureKey]: fromOnlineVisitors.State;
  [fromHub.hubFeatureKey]: fromHub.State;
  [fromStatus.statusFeatureKey]: fromStatus.State;
  [fromActiveDeliveries.activeDeliveriesFeatureKey]: fromActiveDeliveries.State;
  [fromOnline.onlineFeatureKey]: fromOnline.State;
  [fromDelivery.deliveryFeatureKey]: fromDelivery.State;
  [fromHubManager.hubManagerFeatureKey]: fromHubManager.State;
  [fromStores.storesFeatureKey]: fromStores.State;
  [fromUserLocation.userLocationFeatureKey]: fromUserLocation.State;
  [fromActiveErrands.activeErrandsFeatureKey]: fromActiveErrands.State;
  [fromStaff.staffFeatureKey]: fromStaff.State;  [fromDeliveryPositions.deliveryPositionsFeatureKey]: fromDeliveryPositions.State;

}

export const reducers: ActionReducerMap<State, any> = {

  [fromUser.userFeatureKey]: fromUser.reducer,
  [fromOnlineVisitors.onlineVisitorsFeatureKey]: fromOnlineVisitors.reducer,
  [fromHub.hubFeatureKey]: fromHub.reducer,
  [fromStatus.statusFeatureKey]: fromStatus.reducer,
  [fromActiveDeliveries.activeDeliveriesFeatureKey]: fromActiveDeliveries.reducer,
  [fromOnline.onlineFeatureKey]: fromOnline.reducer,
  [fromDelivery.deliveryFeatureKey]: fromDelivery.reducer,
  [fromHubManager.hubManagerFeatureKey]: fromHubManager.reducer,
  [fromStores.storesFeatureKey]: fromStores.reducer,
  [fromUserLocation.userLocationFeatureKey]: fromUserLocation.reducer,
  [fromActiveErrands.activeErrandsFeatureKey]: fromActiveErrands.reducer,
  [fromStaff.staffFeatureKey]: fromStaff.reducer,
  [fromDeliveryPositions.deliveryPositionsFeatureKey]: fromDeliveryPositions.reducer,
};


export const metaReducers: MetaReducer<State>[] = !environment.production ? [] : [];
