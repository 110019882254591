import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { MessageService } from 'primeng/api';
//import { catchError, mergeMap } from 'rxjs/operators';
import { Observable, catchError, mergeMap, map, of } from 'rxjs';
import { Action } from '@ngrx/store';
import { GlobalService } from 'src/app/services/global.service';
import { HttpserviceService } from 'src/app/services/httpservice.service';
import * as UserLocationActions from './user-location.actions'
import { environment } from 'src/environments/environment';


@Injectable()
export class UserLocationEffects {
  constructor(
    private actions$: Actions,
    private httpserviceService: HttpserviceService,
    private globalService: GlobalService,
    private messageService: MessageService,
  ) {}

  getUserLocation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserLocationActions.loadUserLocations),
      mergeMap(async () =>{
        let getEnvironment = <any>{}
        getEnvironment = await this.httpserviceService.http.get(this.globalService.getDynamicUrl())
          .pipe(
            map((res: any) => {
              if(getEnvironment?.api_url){
                environment.apiUrl = getEnvironment?.api_url + "/api/"
              }
              if(getEnvironment?.db_url){
                localStorage.setItem("country_db_url_foodbeeper_hub", getEnvironment?.db_url);
                environment.firebaseConfig.databaseURL = getEnvironment?.db_url
              }
              UserLocationActions.loadUserLocationsSuccess({ data: res })
            }),
            catchError(this.httpserviceService.handleErrorFunction)
          ).toPromise()
          .catch((err: any) => {
            of(UserLocationActions.loadUserLocationsFailure({ error: JSON.stringify(err)}))
            this.messageService.clear();
            this.messageService.add({key: 'KeyHome', severity:'error', summary: 'Erreur', detail: JSON.stringify(err)});
            }
          );
        return getEnvironment;
      })
    )
  )
}
